'use client'

import CloseButton from '@component/closeButton'
import FullPopup from '@component/fullPopup'
import { login } from '@store/index'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useCallback } from 'react'
import dynamic from 'next/dynamic'
const BindFacebookContent = dynamic(() => import('./content'), { ssr: false, loading: () => <Skeleton></Skeleton> })

export default function BindFacebook() {

  const { bindFacebook } = useRecoilValue(login)
  const changeLoginState = useSetRecoilState(login)

  const closeHandle = useCallback(() => {
    changeLoginState(d => ({ ...d, bindFacebook: false }))
  }, [changeLoginState])

  return (
    <FullPopup show={bindFacebook} smCenter mobileFull contentClassName='!bg-transparent' zIndex={301}>
      <CloseButton className='absolute right-0 top-0 z-[2] sm:!text-1xl sm:w-38 sm:h-38' iconClassName='text-neutral-5' circle onClick={closeHandle}></CloseButton>
      <div className='relative w-screen h-fill-screen bg-white p-14 overflow-auto sm:overflow-visible sm:w-330 sm:h-auto sm:rounded-sm'>
        {
          bindFacebook && <BindFacebookContent onClose={closeHandle}></BindFacebookContent>
        }
      </div>
    </FullPopup>
  )
}

function Skeleton() {

  return (
    <div className='skeleton-wrap'>
      <span className='h-16 w-2/3 mx-auto'></span>
      <span className='mt-10 h-12'></span>
      <span className='mt-4 h-12'></span>
      <span className='mt-4 h-12 w-1/5'></span>
      <div className='mt-16 flex gap-x-10'>
        <span className='w-40 h-40 !rounded-half'></span>
        <div className='flex-1 flex flex-col justify-around'>
          <span className='h-12 w-1/4'></span>
          <span className='h-12 w-3/4'></span>
        </div>
      </div>
      <span className='mt-20 h-30'></span>
      <span className='mt-6 h-30'></span>
      <span className='mt-16 h-40'></span>
    </div>
  )
}