'use client'

import Avatar from '@component/avatar'
import useLogin from '@hooks/useLogin'
import useUserState from '@hooks/useUserState'
import getRouter, { URLS } from '@router'
import { joinStr, stopPropagation } from '@utils'
import classNames from 'classnames'
import { get, isFunction } from 'lodash-es'
import Link from 'next/link'
import { useHeaderContext } from '../context'
import HeaderMenuPopup from '../popup'

export function ProfileMenuPopup() {
  const { showProfileMenu, toggleProfileMenu } = useHeaderContext()

  const { user, handleSignout } = useLogin()
  const { nums } = useUserState()

  if (!user) return null

  const name = joinStr([user.firstName, user.lastName], ' ')

  const profileMenu = [
    <ProfileMenuItem avatar={<Avatar circle src={user.avatar} alt={name} width={28} height={28} defaultIcon='icon-avatar'></Avatar>} key='user' first><span className='text-neutral-5'>Hi, {user.name}</span></ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profile)} icon='icon-profile' key='profile'>My profile</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileSavedSearch)} icon='icon-saved-search' num={get(nums, 'criteria', 0)} key='savedsearch'>Saved search</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileLists)} icon='icon-saved-search' num={get(nums, 'fav', 0)} key='savedproperties'>Saved properties</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileNotification)} icon='icon-notification' num={get(nums, 'notification', 0)} key='notifications'>Notifications</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileHistory)} icon='icon-history' key='history'>View history</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileEnquiry)} icon='icon-email' key='emailenquiry'>Email enquiry</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileCommunications)} icon='icon-communications' key='communications' mobileShow>Communications</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileJourney)} icon='icon-calendar' key='myplanner' mobileShow>My planner</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileTrackedSuburb)} icon='icon-tracked-places' key='trackedplaces' mobileShow>Tracked places</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profilePassword)} icon='icon-password' key='changepassword' mobileShow>Change password</ProfileMenuItem>,
    <ProfileMenuItem icon='icon-logout' onClick={handleSignout} key='logout'>Log out</ProfileMenuItem>
  ]

  return (
    <HeaderMenuPopup contentRight show={showProfileMenu} toggleShow={toggleProfileMenu}>
      <div
        className={classNames(
          'bg-white w-[70vw] h-full overflow-auto overscroll-contain transition-transform flex flex-col',
          showProfileMenu ? 'translate-x-0' : 'translate-x-full'
        )}
        onClick={stopPropagation}
      >
        {profileMenu}
        <div className='flex-grow -mb-1'></div>
      </div>
    </HeaderMenuPopup>
  )
}

function ProfileMenuItem({
  children,
  href,
  onClick,
  avatar,
  icon,
  first = false,
  mobileShow = false,
  num
}) {

  const { toggleProfileMenu } = useHeaderContext()
  const className = classNames('flex items-center px-16 h-56 group', { 'sm:hidden': mobileShow }, first ? 'cursor-default' : 'cursor-pointer border-t border-neutral')
  const Tag = href ? Link : 'div'

  function handleClick() {
    isFunction(onClick) && onClick()
    toggleProfileMenu()
  }

  return (
    <Tag href={href} className={className} onClick={handleClick}>
      {avatar}
      <i className={classNames('!text-lg icon', icon)}></i>
      <div className='flex-1 px-8 text-sm'>{children}</div>
      {num > 0 && <span className='text-secondary text-sm'>{num}</span>}
    </Tag>
  )
}
