'use client'

import LoginDialog from '@component/login'
import BindFacebook from '@component/login/bindFacebook'
import NoPassword from '@component/login/noPassword'
import Enquiry from '@component/pages/enquiry'
import SaveListing from '@component/pages/saveListing'
import SaveSearch from '@component/pages/saveSearch'
import TrackProperty from '@component/pages/trackProperty'
// import NewUserDialog from '@component/pages/newUserDialog'
import PageDataLayer from '@component/dataLayer'
import initializeBraze from '@plugin/braze'
import { Suspense } from 'react'
import { useMount } from 'react-use'
import BaseInfo from './base'

export default function FrontendLayout() {

  useMount(() => {
    // 初始化braze
    initializeBraze()
  })

  return (
    <>
      <Suspense>
        <BaseInfo></BaseInfo>
      </Suspense>
      <Suspense>
        {/* 登录框 */}
        <LoginDialog></LoginDialog>
        {/* 没有密码提示(第一次使用google|facebook登录), 后面用goole|facebook邮箱来登录, 表示没有设置密码 */}
        <NoPassword></NoPassword>
        {/* facebook第一次登录需要绑定邮箱 */}
        <BindFacebook></BindFacebook>
        {/* 新用户提示 */}
        {/* <NewUserDialog></NewUserDialog> */}
      </Suspense>
      {/* 房源咨询/中介咨询/中介公司咨询/builder咨询 */}
      <Enquiry></Enquiry>
      {/* 认领房源 */}
      <TrackProperty></TrackProperty>
      {/* 保存房源 */}
      <SaveListing></SaveListing>
      {/* 保存搜索 */}
      <SaveSearch></SaveSearch>
      {/* datalayer */}
      <PageDataLayer></PageDataLayer>
    </>
  )
}