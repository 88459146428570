import { saveSearch } from '@store/index'
import { useSetRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import { useCallback } from 'react'
import { saveSearchDataLayer } from '@plugin/google/gtm/modules/houseList'

export default function useSaveSearch() {

  const state = useRecoilValue(saveSearch)
  const setSaveSearchState = useSetRecoilState(saveSearch)
  const resetSaveSearchState = useResetRecoilState(saveSearch)


  // 显示保存搜索
  const showSaveSearch = useCallback(({ data, state, suburbs, signupSource = 'Save_Search' }, successCallback) => {
    return event => {

      event.preventDefault()
      event.stopPropagation()
      saveSearchDataLayer.click()
      saveSearchDataLayer.show()
      setSaveSearchState(d => ({ ...d, show: true, data, state, suburbs, signupSource, successCallback }))
    }
  }, [setSaveSearchState])


  // 隐藏save search
  const hideSaveSearch = useCallback(() => {
    resetSaveSearchState()
  }, [resetSaveSearchState])

  return {
    ...state,
    showSaveSearch,
    hideSaveSearch
  }
}