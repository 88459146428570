'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import { ResponsiveImageBgLoading } from '@component/imageBgLoading'
import IconLogo from '@icon/const/logo-desktop.svg?url'
import getRouter, { URLS } from '@router'
import classNames from 'classnames'
import { useHeaderContext } from '../context'

export default function HeaderLogo() {
  const { showNavMenu, toggleNavMenu } = useHeaderContext()

  return (
    <div className='h-50 flex items-center gap-x-10 sm:h-auto'>
      <button className='p-10 -m-10 sm:hidden' onClick={toggleNavMenu}>
        <span className={classNames('menu-btn w-24 h-14', { 'open': showNavMenu })}>
          <i></i>
        </span>
      </button>
      <DisablePrefetchLink prefetch={false} href={getRouter(URLS.homepage)} className='min-w-0' aria-label='go to homepage'>
        <Logo
          src={IconLogo}
          msrc={IconLogo}
          width={150}
          height={28}
          className='w-124 h-30 block sm:w-150 sm:h-28'
        ></Logo>
      </DisablePrefetchLink>
    </div>
  )
}

function Logo({
  src,
  msrc,
  width,
  height,
  className
}) {

  return (
    <ResponsiveImageBgLoading
      useLoading={false}
      src={src}
      mobileSrc={msrc}
      width={width}
      height={height}
      alt='logo'
      className={classNames('pointer-events-none', className)}
      unoptimized
    ></ResponsiveImageBgLoading>
  )
}