import Avatar from '@component/avatar'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import Menu from '@component/menu'
import useLogin from '@hooks/useLogin'
import useUserState from '@hooks/useUserState'
import getRouter, { URLS } from '@router'
import { joinStr } from '@utils'
import classNames from 'classnames'
import { get } from 'lodash-es'
import Link from 'next/link'
import { useEffect } from 'react'
import { useHeaderContext } from '../context'

export default function AvatarPopup({
  children,
  user
}) {

  const { showProfileMenu, toggleProfileMenu } = useHeaderContext()
  const { nums, updateUserNum } = useUserState()
  const { handleSignout } = useLogin()
  const name = joinStr([user.firstName, user.lastName], ' ')

  const profileMenu = [
    <ProfileMenuItem avatar={<Avatar circle src={user.avatar} alt={name} width={28} height={28} defaultIcon='icon-avatar'></Avatar>} key='user' first><span className='text-neutral-5'>Hi, {user.name}</span></ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profile)} icon='icon-profile' key='profile'>My profile</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileSavedSearch)} icon='icon-saved-search' num={get(nums, 'criteria', 0)} key='savedsearch'>Saved search</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileLists)} icon='icon-saved-search' num={get(nums, 'fav', 0)} key='savedproperties'>Saved properties</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileNotification)} icon='icon-notification' num={get(nums, 'notification', 0)} key='notifications'>Notifications</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileHistory)} icon='icon-history' key='history'>View history</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileEnquiry)} icon='icon-email' key='emailenquiry'>Email enquiry</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileCommunications)} icon='icon-communications' key='communications' mobileShow>Communications</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileJourney)} icon='icon-calendar' key='myplanner' mobileShow>My planner</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profileTrackedSuburb)} icon='icon-tracked-places' key='trackedplaces' mobileShow>Tracked places</ProfileMenuItem>,
    <ProfileMenuItem href={getRouter(URLS.profilePassword)} icon='icon-password' key='changepassword' mobileShow>Change password</ProfileMenuItem>,
    <ProfileMenuItem icon='icon-logout' onClick={handleSignout} key='logout'>Log out</ProfileMenuItem>
  ]

  useEffect(() => {
    if (showProfileMenu) updateUserNum()
  }, [showProfileMenu, updateUserNum])
  return (
    <>
      <Menu right width={315} data={profileMenu} onMouseEnter={updateUserNum} className='hidden sm:block'>
        <DisablePrefetchLink prefetch={false} href={getRouter(URLS.profile)}>{children}</DisablePrefetchLink>
      </Menu>
      <button className='sm:hidden' onClick={toggleProfileMenu}>
        {
          showProfileMenu && (
            <div className='w-32 h-32 flex items-center justify-center'>
              <i className='menu-btn open w-24 h-14' />
            </div>
          )
        }
        <div className={classNames({ 'hidden': showProfileMenu })}>{children}</div>
      </button>
    </>
  )
}

function ProfileMenuItem({
  children,
  href,
  onClick,
  avatar,
  icon,
  first = false,
  mobileShow = false,
  num
}) {

  const className = classNames('flex items-center px-16 h-56 group', { 'sm:hidden': mobileShow }, first ? 'cursor-default' : 'cursor-pointer border-t border-neutral hover:bg-primary-light hover:text-primary')
  const Tag = href ? Link : 'div'
  return (
    <Tag href={href} className={className} onClick={onClick}>
      {avatar}
      <i className={classNames('!text-2xl text-neutral-5 group-hover:text-primary icon', icon)}></i>
      <div className='flex-1 text-neutral-5 text-sm px-8 group-hover:text-primary'>{children}</div>
      {num > 0 && <span className='text-secondary text-sm group-hover:text-primary'>{num}</span>}
    </Tag>
  )
}
