'use client'

import httpClient from '@apiClient'
import CloseButton from '@component/closeButton'
import CheckboxContent from '@component/form/checkbox/content'
import MaskLoading from '@component/loading/mask'
import useConfirm from '@hooks/useConfirm'
import useEnquiry from '@hooks/useEnquiry'
import useSaveListing from '@hooks/useSaveListing'
import useUserState from '@hooks/useUserState'
import { favouriteDataLayer } from '@plugin/google/gtm/modules/property'
import { joinStr } from '@utils'
import { get, includes, isFunction, map, size, transform } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useBoolean, useMount } from 'react-use'
import NewFolder from './newFolder'

export default function SaveListingContent() {

  const { savedHouseIds } = useUserState()
  const { houseId, house, hideSaveListing, changeSaved, toggleSaving, showFolder, saving } = useSaveListing()
  // 保存成功提示
  const { openConfirm } = useConfirm()
  const { showHouseEnquiry } = useEnquiry()
  const showSuccessTip = useCallback(() => {
    openConfirm({
      title: 'Success',
      content: (
        <div className='text-left space-y-20'>
          <div>This property has been saved.</div>
          <div>Enquire now for more information or a price indication.</div>
        </div>
      ),
      confirmText: 'Maybe later',
      cancelText: 'Enquire',
      confirmClassName: 'button-primary-out',
      cancelClassName: 'button-orange',
      onCancel() {
        const agent = get(house, 'agents[0]')
        showHouseEnquiry({ house, agent, signupSource: 'agent_enquiry' })()
      }
    })
  }, [openConfirm, showHouseEnquiry, house])

  // 文件列表
  const [data, setData] = useState()
  // 获取文件列表中
  const [isLoading, setIsLoading] = useBoolean(false)
  // 获取文件列表
  const fetchFolders = useCallback(async callback => {
    setIsLoading(true)
    httpClient.cfCache = false
    return await httpClient.get('folders', { houseId })
      .then(d => {
        setData(d)
        if (isFunction(callback)) return callback(d)
      })
      .finally(() => setIsLoading(false))
  }, [houseId, setData, setIsLoading])

  // 保存房源到默认文件夹
  const saveDefaultListing = useCallback(() => {
    toggleSaving(true)
    httpClient.post('folder/item', { houseId, folderIds: -1 })
      .then(() => {
        changeSaved(true)
        favouriteDataLayer.success()
        showSuccessTip()
      })
      .finally(() => toggleSaving(false))
  }, [houseId, changeSaved, toggleSaving, showSuccessTip])
  const deleteDefaultListing = useCallback(() => {
    toggleSaving(true)
    httpClient.delete('folder/item', { houseId, folderId: -1 })
      .then(() => {
        changeSaved(false)
      })
      .finally(() => toggleSaving(false))
  }, [houseId, changeSaved, toggleSaving])

  useMount(async () => {
    if (showFolder) {
      setIsLoading(true)
      await httpClient.post('folder/item', { houseId, folderIds: -1 }).catch(() => undefined).finally(() => setIsLoading(false))
      changeSaved(true)
      favouriteDataLayer.success()
      fetchFolders()
      return
    }
    if (includes(savedHouseIds, houseId)) {
      deleteDefaultListing()
      hideSaveListing()
    } else {
      saveDefaultListing()
      hideSaveListing()
    }
  })

  // 选项列表
  const [checkList, setCheckList] = useState()
  // 文件夹名字
  const [folderName, setFolderName] = useState('')
  // 是否显示新建文件夹表单
  const [showNewFolder, setShowNewFolder] = useBoolean(false)
  // 设置默认值
  useEffect(() => {
    if (!data) return
    const val = {}
    map(data, item => val[item.id] = item.isFav)
    setCheckList(val)
  }, [data, setCheckList])
  // 选项改变
  const itemChange = useCallback(item => {
    return checked => {
      setCheckList(d => ({ ...d, [item.id]: checked }))
    }
  }, [setCheckList])
  // 设置文件夹名字
  const inputChangeHandle = useCallback(event => {
    setFolderName(event.target.value)
  }, [setFolderName])
  // toggle display new floder form
  const toggleNewFolder = useCallback(() => {
    if (showNewFolder) fetchFolders()
    setShowNewFolder(!showNewFolder)
  }, [setShowNewFolder, showNewFolder, fetchFolders])
  // 保存房源收藏
  const saveListing = useCallback(async () => {
    const folderIds = transform(checkList, (result, val, key) => {
      val && result.push(key)
    }, [])
    toggleSaving(true)
    await httpClient.put('folder/item', { houseId, folderIds: joinStr(folderIds, ',') }).finally(() => toggleSaving(false))
    const saved = size(folderIds) > 0
    changeSaved(saved)
    hideSaveListing()
    favouriteDataLayer.success()
  }, [houseId, changeSaved, toggleSaving, checkList, hideSaveListing])

  return showFolder && (
    <div className='relative px-10 pt-50 sm:px-26'>
      <CloseButton className='absolute z-10 right-16 top-6' onClick={hideSaveListing}></CloseButton>
      {
        showNewFolder
          ? <NewFolder name={folderName} houseId={houseId} onClose={toggleNewFolder}></NewFolder>
          : (
            <>
              <div className='flex'>
                <input type='text' className='flex-1 border border-r-0 h-48 rounded-l-sm px-16 text-sm' placeholder='Create a new list' value={folderName} onChange={inputChangeHandle} />
                <button className='w-48 h-48 button-primary rounded-l-none' onClick={toggleNewFolder}>
                  <i className='icon icon-plus !text-xl text-white font-bold'></i>
                </button>
              </div>
              <div className='text-xl text-font mt-16'>Save to lists</div>
              <div className='max-h-280 overflow-auto mt-12 space-y-10'>
                {
                  checkList && map(data, item => (
                    <div key={item.id}>
                      <CheckboxContent checked={checkList[item.id]} onChange={itemChange(item)}>{item.name}</CheckboxContent>
                    </div>
                  ))
                }
              </div>
              <button type='button' className='button-primary w-full mt-16' onClick={saveListing} disabled={saving}>{saving ? 'loading...' : 'Done'}</button>
              <MaskLoading loading={isLoading}></MaskLoading>
            </>
          )
      }
    </div>
  )
}
