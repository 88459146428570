'use client'

import CloseButton from '@component/closeButton'
import useEnquiry from '@hooks/useEnquiry'
import AgentCard from './card/HT-1804/agent'
import Agent from './card/agent'
import Builder from './card/builder'
import Office from './card/office'
import EnquiryForm from './form'
import { usePageAlt } from '@component/abTest'
import classNames from 'classnames'

export default function EnquiryContent({
}) {

  const {
    house,
    agent,
    office,
    builder,
    isAgent,
    isHouse,
    isOffice,
    isBuilder,
    isDisplayHome,
    hideEnquiry
  } = useEnquiry()

  return (
    <div className='w-full max-h-screen relative bg-white p-10 overflow-auto sm:px-30 sm:pt-30 sm:pb-20 sm:max-w-[94vw] sm:h-auto sm:rounded-sm sm:w-[800px]'>
      <CloseButton className='absolute right-6 top-6 z-10' onClick={hideEnquiry}></CloseButton>
      {
        // (isAgent || isHouse) && agent && (
        //   <ABTest newComponent={<AgentCard data={agent} house={house}></AgentCard>}>
        //     <Agent data={agent} house={house}></Agent>
        //   </ABTest>
        // )
        isAgent && agent && <Agent data={agent} house={house}></Agent>
      }
      {
        isHouse && agent && (
          <AgentCard data={agent} house={house}></AgentCard>
        )
      }
      {
        isOffice && office && (
          <Office data={office}></Office>
        )
      }
      {
        (isBuilder || isDisplayHome) && builder && (
          <Builder data={builder}></Builder>
        )
      }
      <EnquiryForm></EnquiryForm>
    </div>
  )
}