import ImageBgLoading from '@component/imageBgLoading'

export default function Office({
  data
}) {

  return (
    <div className='grid grid-cols-1 gap-x-10 mr-20 sm:grid-cols-[1fr,auto] sm:mr-0'>
      <div className='min-w-0 space-y-4'>
        <div className='text-font text-base'>{data.name}</div>
        <address className='text-sm text-neutral-5 not-italic'>Address: {data.address}</address>
        <div className='text-sm text-neutral-5'>Phone number: {data.phoneNumber}</div>
      </div>
      {
        data.icon && (
          <ImageBgLoading src={data.icon} width={200} height={44} alt={data.name} className='object-contain object-right self-center'></ImageBgLoading>
        )
      }
    </div>
  )
}