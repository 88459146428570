import ImageBgLoading from '@component/imageBgLoading'

export default function Builder({
  data
}) {

  return (
    <div className='flex items-center justify-between' style={{ backgroundColor: data.bgColor, color: data.fontColor }}>
      <div className='space-y-5'>
        <div>{data.name}</div>
        <div>{data.address}</div>
        <div>{data.phone}</div>
      </div>
      {
        data.logo && (
          <ImageBgLoading src={data.logo} width={200} height={44} alt={data.name} className='object-contain object-right self-center'></ImageBgLoading>
        )
      }
    </div>
  )
}