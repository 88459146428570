'use client'

import ActionBefore, { ActionSekelton, TopSekelton } from '@component/pages/actionBefore'
import useSaveListing from '@hooks/useSaveListing'
import ImageHouseCover from '@image/house/house-cover.jpg'
import IconSaveListing from '@image/house/save-listing.png'
import { favouriteDataLayer } from '@plugin/google/gtm/modules/property'
import { get } from 'lodash-es'
import dynamic from 'next/dynamic'
import { useUnmount } from 'react-use'
import SaveListingContent from './content'
const loading = () => <ActionSekelton></ActionSekelton>
const StepTop = dynamic(() => import('@component/pages/actionBefore/steps/top'), { ssr: false, loading: () => <TopSekelton></TopSekelton> })
const StepEmail = dynamic(() => import('@component/pages/actionBefore/steps/email'), { ssr: false, loading })
const StepPassword = dynamic(() => import('@component/pages/actionBefore/steps/password'), { ssr: false, loading })

export default function SaveListing() {


  const { show, saving, house, signupSource, hideSaveListing } = useSaveListing()
  useUnmount(() => {
    hideSaveListing()
  })

  return (
    <>
      <ActionBefore
        show={show}
        signupSource={signupSource}
        steps={[
          <StepEmail
            title='Save this property'
            key='step-email'
            signinClick={favouriteDataLayer.signin}
            onRegister={registered => registered ? favouriteDataLayer.registered() : favouriteDataLayer.notRegistered()}
          ></StepEmail>,
          <StepPassword
            signinText={<>Sign in to your account to<br />save this property.</>}
            signupTitle='You are almost there!'
            signupText={<>Create a password to save<br />this property</>}
            buttonText='SAVE THIS PROPERTY'
            key='step-password'
          ></StepPassword>
        ]}
        onClose={hideSaveListing}
        top={
          <StepTop
            cover={ImageHouseCover}
            lat={get(house, 'lat')}
            lng={get(house, 'lng')}
            zoom={20}
            icon={IconSaveListing}
            iconSize={40}
            iconClassName='bg-secondary'
            onClose={hideSaveListing}
          ></StepTop>
        }
      >
        <SaveListingContent></SaveListingContent>
      </ActionBefore>
    </>
  )
}
