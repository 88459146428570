import DisablePrefetchLink from '@component/disablePrefetchLink'
import { getRouterHref } from '@router'
import classNames from 'classnames'
import { isObject } from 'lodash-es'
import { usePathname } from 'next/navigation'

export default function ActiveLink({
  children,
  className,
  style,
  activeClassName,
  target,
  rel,
  href,
  onClick,
  ...args
}) {

  const url = isObject(href) ? getRouterHref(href).pathname : href
  const pathname = usePathname()
  const isActive = pathname.startsWith(url)

  return (
    <DisablePrefetchLink
      href={href}
      className={classNames([className, isActive ? activeClassName : ''])}
      target={target}
      rel={rel}
      style={style}
      onClick={onClick}
      {...args}
    >
      {children}
    </DisablePrefetchLink>
  )
}