'use client'

import { usePageAlt } from '@component/abTest'
import AgentsCarousel, { useAgentsCarousel } from '@component/card/agent/carousel'
import ImageBgLoading from '@component/imageBgLoading'
import Tooltip from '@component/tooltip'
import useEnquiry from '@hooks/useEnquiry'
import IconCopy from '@icon/house/copy.svg'
import { global } from '@store/index'
import { blockNumber } from '@utils'
import classNames from 'classnames'
import ClipboardJS from 'clipboard'
import { findIndex, get, pick, size } from 'lodash-es'
import { useEffect, useMemo, useRef } from 'react'
import { useBoolean } from 'react-use'
import { useRecoilValue } from 'recoil'

export default function AgentCard({
  data,
  house
}) {
  const office = useMemo(() => {
    if (house) {
      if (house.office) {
        return pick(house.office, ['icon', 'name', 'bgColor'])
      }
    } else if (data.office) {
      return pick(data.office, ['icon', 'name', 'bgColor'])
    }
  }, [data, house])
  const agents = useMemo(() => {
    const list = get(house, 'agents', [])
    if (size(list) >= 3) {
      return [...list, ...list]
    } else {
      return list
    }
  }, [house])
  const defaultSlide = findIndex(agents, { id: data.id })

  return (
    <div className='flex'>
      <div className='flex-1 sm:flex-none'>
        <AgentsCarousel
          name='enquiry'
          data={get(house, 'agents', [])}
          defaultSlide={defaultSlide}
          rightSlot={
            <AgentName className='hidden sm:block' office={office} />
          }
        >
          <AgentName className='sm:hidden' office={office} />
        </AgentsCarousel>
      </div>
      <OfficeLogo data={office} className='hidden sm:block ml-auto' />
    </div>
  )
}

function AgentName({
  office,
  className = ''
}) {
  const { currentAgent } = useAgentsCarousel()
  const { isMobile } = useRecoilValue(global)

  const [showPhone, togglePhone] = useBoolean(false)
  const [copySuccess, toggleCopySuccess] = useBoolean(false)
  const copyRef = useRef(null)
  const timer = useRef(null)
  const { changeHouseEnquiryAgent } = useEnquiry()
  useEffect(() => {
    changeHouseEnquiryAgent(currentAgent)
  }, [currentAgent, changeHouseEnquiryAgent])

  useEffect(() => {
    if (!copyRef.current || !showPhone) return

    const clipboard = new ClipboardJS(copyRef.current, {
      text: () => currentAgent.phoneNumber
    })
    clipboard.on('success', () => {
      toggleCopySuccess(true)
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        toggleCopySuccess(false)
      }, 3000)
    })

    return () => {
      clipboard.destroy()
    }
  }, [currentAgent, copyRef, timer, showPhone, toggleCopySuccess])

  function copyHandle(event) {
    event.preventDefault()
  }

  return (
    <div className={classNames('ml-10', className)}>
      <div className='text-font text-lg font-medium text-center sm:text-left'>{currentAgent.name}</div>
      <div className='mt-8 flex items-center gap-x-10'>
        <OfficeLogo data={office} iconWidth={107} iconHeight={24} className='sm:hidden' />
        {
          currentAgent.phoneNumber && (
            <Tooltip zIndex={201} active={copySuccess} disabled bottom={isMobile} right={!isMobile} content={<div className='text-secondary text-md px-10 py-2'>Copied!</div>}>
              <a href={`tel:${currentAgent.phoneNumber}`} type='button' className='button-primary-out h-32' onClick={() => togglePhone(true)} data-noprogress>
                <i className='icon icon-phone text-primary'></i>
                <span className='ml-8'>{showPhone ? currentAgent.phoneNumber : blockNumber(currentAgent.phoneNumber)}</span>
                {
                  showPhone && (
                    <>
                      <i className='w-1 h-14 bg-primary ml-8'></i>
                      <button className='cursor-pointer h-full px-8 -mr-8' onClick={copyHandle} ref={copyRef}>
                        <IconCopy width={14} height={14} fill='var(--color-theme)' className='block'></IconCopy>
                      </button>
                    </>
                  )
                }
              </a>
            </Tooltip>
          )
        }
      </div>
    </div>
  )
}

function OfficeLogo({
  data,
  className,
  imgClassName,
  iconWidth = 200,
  iconHeight = 36
}) {
  return (
    get(data, 'icon') && (
      <div style={{ backgroundColor: data.bgColor }} className={classNames('sm:self-center p-4 rounded-sm', className)}>
        <ImageBgLoading src={get(data, 'icon')} width={iconWidth} height={iconHeight} alt={get(data, 'name')} className={classNames('object-contain object-center block', imgClassName)}></ImageBgLoading>
      </div>
    )
  )
}