export default async function initializeBraze() {
  // import braze skd
  const braze = await import('@braze/web-sdk')
  // initialize
  const result = braze.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY, {
    baseUrl: process.env.NEXT_PUBLIC_BRAZE_BASE_URL
  })
  console.log('braze.initialize', result)
  if (!result) return

  // show all in-app messages without custom handling
  // braze.automaticallyShowInAppMessages()


  // subscribe to receive in-app messages
  braze.subscribeToInAppMessage(inAppMessage => {
    if (inAppMessage.isControl) { // v4.5.0+, otherwise use  `inAppMessage instanceof braze.ControlMessage`
      return braze.showInAppMessage(inAppMessage)
    }

    if (inAppMessage instanceof braze.InAppMessage) {
      let extras = inAppMessage.extras || {}
      if (extras['custom_html'] && extras['dom']) {
        const dom = document.querySelector(extras.dom)
        if (dom) {
          try {
            dom.append(inAppMessage.message)
          } catch (error) {
            dom.innerHTML = inAppMessage.message
          }
        }
      } else {
        braze.showInAppMessage(inAppMessage)
      }
    }
  })

  // set the current user's external ID
  // if (isLoggedIn) {
  //   braze.changeUser(userIdentifier)
  // }

  // Be sure to call `openSession` after `automaticallyShowInAppMessages`
  braze.openSession()

  // 放到全局给nzme的人使用
  window.appboyBridge = braze
}