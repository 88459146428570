import { assign } from 'lodash-es'
import Script from 'next/script'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'
import useLogin from './useLogin'

export default function useGoogleLogin({
  small = false,
  button,
  onClick,
  onSuccess,
  resize = true,
  ops
} = {}) {

  const { info } = useLogin()
  const [loaded, toggleLoaded] = useState(false)
  // cancel
  const cancel = useCallback(() => {
    if (typeof google !== 'undefined' && google.accounts) {
      try {
        google.accounts.id.cancel()
      } catch (e) {
      }
    }
  }, [])
  // init client
  const initialize = useCallback(() => {
    if (typeof google === 'undefined' || info) return
    cancel()
    google.accounts.id.initialize({
      cancel_on_tap_outside: false,
      use_fedcm_for_prompt: true,
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENTID,
      callback: onSuccess
    })
    toggleLoaded(true)
  }, [onSuccess, toggleLoaded, cancel, info])
  // render button
  const [preWidth, setPreWidth] = useState(-1)
  const renderButton = useCallback(() => {
    if (button && button.current) {
      const width = button.current.offsetWidth
      if (!loaded || width === preWidth) return
      setPreWidth(button.current.offsetWidth)

      const config = small ? {
        size: 'medium',
        type: 'icon',
        shape: 'circle'
      } : {
        size: 'large',
        type: 'standard',
        theme: 'outline',
        text: 'continue_with',
        logo_alignment: 'center',
        width
      }

      window.google.accounts.id.renderButton(button.current, assign(config, { click_listener: onClick, locale: 'en' }, ops))
    }
    prompt()
  }, [button, loaded, small, onClick, ops, preWidth, setPreWidth])

  const { width } = useWindowSize()
  const listenResize = useMemo(() => resize && !small ? width : 0, [resize, small, width])
  useEffect(() => {
    if (info) {
      cancel()
      return
    }
    renderButton()
  }, [renderButton, listenResize, info, cancel])

  const googleLoginChildren = useMemo(() => {
    return (
      <Script src='https://accounts.google.com/gsi/client' strategy='lazyOnload' onReady={initialize}></Script>
    )
  }, [initialize])

  return {
    googleLoginChildren,
    loaded,
    cancel
  }
}

function prompt() {
  if (typeof google === 'undefined') return
  try {
    google.accounts.id.prompt()
  } catch (error) { }
}