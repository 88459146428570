'use client'

import classNames from 'classnames'
import { map } from 'lodash-es'
import { useEffect, useState } from 'react'

export default function HeaderMenuPopup({
  show = false,
  contentLeft = false,
  contentRight = false,
  children,
  toggleShow
}) {
  const [headerTop, setHeaderTop] = useState(50)

  useEffect(() => {
    let header = document.getElementsByTagName('header')
    if (header.length > 0) {
      header = header[0]
    }

    function fitHeaderTop() {
      setHeaderTop(header.offsetTop + header.offsetHeight - document.body.scrollTop - document.body.parentElement.scrollTop)
    }
    fitHeaderTop()

    // 禁止header/openAppBanner的touchmove事件，防止拖拽滚动
    function disableTouchMove(event) {
      event.preventDefault()
    }

    const observer = new MutationObserver((mutationList) => {
      map(mutationList, (mutation) => {
        map(mutation.removedNodes, (node) => {
          if (node.id === 'open-app-banner') {
            fitHeaderTop()
          }
        })
      })
    });
    const openAppBanner = document.getElementById('open-app-banner')
    if (show) {
      openAppBanner && openAppBanner.addEventListener('touchmove', disableTouchMove, { passive: false })
      header && header.addEventListener('touchmove', disableTouchMove, { passive: false })
      observer.observe(document.body, { childList: true, subtree: true });
    } else {
      openAppBanner && openAppBanner.removeEventListener('touchmove', disableTouchMove)
      header && header.removeEventListener('touchmove', disableTouchMove)
    }

    return () => {
      openAppBanner && openAppBanner.removeEventListener('touchmove', disableTouchMove)
      header && header.removeEventListener('touchmove', disableTouchMove)
      observer.disconnect()
    }
  }, [show, setHeaderTop])

  return (
    <div
      className={classNames(
        'fixed inset-x-0 bottom-0 z-50 bg-black bg-opacity-50 transition-opacity ease-linear duration-150 overflow-scroll overscroll-contain sm:hidden touch-none',
        show ? 'opacity-100' : 'invisible opacity-0 pointer-events-none'
      )}
      style={{ top: headerTop }}
    >
      <div
        className={classNames(
          'h-full flex',
          { 'justify-start': contentLeft },
          { 'justify-end': contentRight },
        )}
        onClick={toggleShow}
      >
        {children}
      </div>
    </div>
  )
}