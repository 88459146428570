'use client'

import AppendRoot from '@component/appendRoot'
import FullPopup from '@component/fullPopup'
import useEnquiry from '@hooks/useEnquiry'
import { useUnmount } from 'react-use'
import EnquiryContent from './content'
import EnquirySent from './sent'

export default function Enquiry() {

  const { show, success, house, hideEnquiry } = useEnquiry()

  useUnmount(() => {
    hideEnquiry()
  })

  return (
    <>
      <AppendRoot>
        <FullPopup show={show && !success} smCenter mobileFull maskCloseable contentClassName='!bg-transparent p-16 w-full h-full flex items-center justify-center sm:w-auto sm:h-auto'>
          {
            show && !success && (
              <EnquiryContent></EnquiryContent>
            )
          }
        </FullPopup>
      </AppendRoot>
      {
        show && (
          <EnquirySent house={house} show={show && success} onClose={hideEnquiry} />
        )
      }
    </>
  )
}