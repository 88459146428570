'use client'

import Image from 'next/image'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import Script from 'next/script'
import getRouter, { URLS } from '@router'
import classNames from 'classnames'

export default function FooterCopyright () {

  const shieldedId = 'shielded-logo'

  return (
    <>
      <div className='mt-40 flex flex-wrap sm:items-center sm:mt-72'>
        <Item className='sm:flex-1'>&copy; OneRoof Limited</Item>
        <Item href={getRouter(URLS.aboutUs)}>About us</Item>
        <Item href={getRouter(URLS.privacyPolicy)}>Privacy Policy</Item>
        <Item href={getRouter(URLS.disclaimer)}>Terms & Conditions</Item>
        <Item href={getRouter(URLS.userTerms)}>User terms</Item>
        <Item href={getRouter(URLS.faq)}>FAQs</Item>
        <Item href='#' id={shieldedId} data-noprogress>
          <Image src='https://shielded.co.nz/img/custom-logo.png' width={24} height={24} alt='shielded'></Image>
        </Item>
      </div>
      <Script src='https://staticcdn.co.nz/embed/embed.js' strategy='lazyOnload' onLoad={() => {
        new ds07o6pcmkorn({
          openElementId: `#${shieldedId}`,
          modalID: 'modal'
        }).init()
      }}></Script>
    </>
  )
}

function Item ({
  href,
  children,
  className,
  id,
  ...args
}) {
  const styles = classNames('inline-block px-8 py-2 text-xs text-neutral sm:text-sm', className)
  return (
    href
    ? <DisablePrefetchLink className={styles} id={id} href={href} target='_blank' prefetch={false} {...args}>{children}</DisablePrefetchLink>
    : <span className={styles} id={id} {...args}>{children}</span>
  )
}
