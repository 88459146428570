'use client'

import { createContext, useCallback, useContext } from 'react'
import { useBoolean } from 'react-use'

export const HeaderContext = createContext()
export function useHeaderContext() {
  return useContext(HeaderContext)
}

export function HeaderProvider({ children }) {
  const [showNavMenu, setNavMenu] = useBoolean(false)
  const [showProfileMenu, setProfileMenu] = useBoolean(false)

  const toggleNavMenu = useCallback(() => {
    setNavMenu()
    setProfileMenu(false)
  }, [setNavMenu, setProfileMenu])

  const toggleProfileMenu = useCallback(() => {
    setProfileMenu()
    setNavMenu(false)
  }, [setProfileMenu, setNavMenu])

  return (
    <HeaderContext.Provider value={{
      showNavMenu,
      toggleNavMenu,
      showProfileMenu,
      toggleProfileMenu
    }}>
      {children}
    </HeaderContext.Provider>
  )
}