'use client'

import ActionBefore, { ActionSekelton, TopSekelton } from '@component/pages/actionBefore'
import useSaveSearch from '@hooks/useSaveSearch'
import ImageSaveSearch from '@image/house/save-search-cover.jpg'
import IconTrackSuburb from '@image/house/track-suburb.png'
import { saveSearchDataLayer } from '@plugin/google/gtm/modules/houseList'
import dynamic from 'next/dynamic'
import { useUnmount } from 'react-use'
import SaveSearchContent from './content'
const loading = () => <ActionSekelton></ActionSekelton>
const StepTop = dynamic(() => import('@component/pages/actionBefore/steps/top'), { ssr: false, loading: () => <TopSekelton></TopSekelton> })
const StepEmail = dynamic(() => import('@component/pages/actionBefore/steps/email'), { ssr: false, loading })
const StepPassword = dynamic(() => import('@component/pages/actionBefore/steps/password'), { ssr: false, loading })

export default function SaveSearch() {

  const { show, signupSource, hideSaveSearch } = useSaveSearch()
  useUnmount(() => {
    hideSaveSearch()
  })

  return (
    <ActionBefore
      autoWidth
      show={show}
      signupSource={signupSource}
      steps={[
        <StepEmail
          title='Save this search'
          key='step-email'
          signinClick={saveSearchDataLayer.signin}
          onRegister={registered => registered ? saveSearchDataLayer.registered() : saveSearchDataLayer.notRegistered()}
        ></StepEmail>,
        <StepPassword
          signinText={<>Sign in to your account to<br />save this search.</>}
          signupTitle='You are almost there!'
          signupText={<>Create a password to save<br />this search</>}
          key='step-password'
        ></StepPassword>
      ]}
      onClose={hideSaveSearch}
      top={
        <StepTop
          cover={ImageSaveSearch}
          icon={IconTrackSuburb}
          iconSize={40}
          iconClassName='bg-secondary'
          onClose={hideSaveSearch}
        ></StepTop>
      }
    >
      <SaveSearchContent></SaveSearchContent>
    </ActionBefore>
  )
}
