'use client'

import CloseButton from '@component/closeButton'
import FullPopup from '@component/fullPopup'
import MaskLoading from '@component/loading/mask'
import classNames from 'classnames'

export default function LoginBase({
  show,
  title,
  sub,
  children,
  onClose,
  loading,
  zIndex = 300
}) {

  return (
    <FullPopup show={show} smCenter mobileFull contentClassName='!bg-transparent w-full h-full sm:w-auto sm:h-auto sm:max-h-full sm:overflow-auto' zIndex={zIndex}>
      <CloseButton className='absolute right-10 top-10 z-[2] sm:!text-1xl sm:w-38 sm:h-38' iconClassName='text-neutral-5' onClick={onClose}></CloseButton>
      <div className='w-full h-full bg-white py-10 px-14 overflow-auto sm:overflow-visible sm:w-496 sm:h-auto sm:rounded-sm sm:p-48'>
        {title && <div className='text-font text-center text-1xl sm:text-3xl'>{title}</div>}
        {sub && <div className='mt-8 sm:mt-14 text-font text-center text-lg sm:text-xl whitespace-pre-wrap'>{sub}</div>}
        <div className='mt-14'>
          {
            children
          }
        </div>
      </div>
      <MaskLoading loading={loading}></MaskLoading>
    </FullPopup>
  )
}

export function Hr({
  children,
  className
}) {
  return <div className={classNames('py-8 sm:py-14 text-font text-sm text-center', className)}>{children}</div>
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap space-y-16'>
      <div>
        <span className='w-40 h-10'></span>
        <span className='h-30 mt-6'></span>
      </div>
      <div>
        <div className='flex justify-between'>
          <span className='w-40 h-10'></span>
          <span className='w-40 h-10'></span>
        </div>
        <span className='h-30 mt-6'></span>
      </div>
      <span className='h-48 !mt-50'></span>
      <div className='space-y-10 !mt-50'>
        <span className='h-14'></span>
        <span className='h-14'></span>
        <span className='h-14'></span>
      </div>
    </div>
  )
}