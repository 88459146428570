'use client'

import httpClient from '@apiClient'
import CloseButton from '@component/closeButton'
import Form from '@component/form'
import FormButton from '@component/form/button'
import MaskLoading from '@component/loading/mask'
import useFetch from '@hooks/useFetch'
import useTrackProperty from '@hooks/useTrackProperty'
import { trackDataLayer } from '@plugin/google/gtm/modules/property'
import classNames from 'classnames'
import { get, map } from 'lodash-es'
import { useCallback, useState } from 'react'
import { useBoolean, useMount } from 'react-use'

export default function TrackPropertyContent() {

  const { house, trackedList, hideTrackProperty, changeTracked, toggleTracking } = useTrackProperty()
  const { data, isLoading } = useFetch({ url: 'property-track-config', params: { arr: 1 } })
  const [config, setConfig] = useState()
  const [success, toggleSuccess] = useBoolean(false)
  const tracked = get(house, 'tracked', false) || get(trackedList, house.propertyId, false)

  const trackProperty = useCallback(async trackType => {
    toggleTracking(true)
    await httpClient.put(`property-track/${house.propertyId}`, trackType ? { trackType } : undefined).finally(() => {
      toggleTracking(false)
    })

    changeTracked(true)
    trackDataLayer.success()
  }, [house, changeTracked, toggleTracking])
  const stopTrack = useCallback(async () => {
    toggleTracking(true)
    await httpClient.delete(`property-track/${house.propertyId}`).finally(() => {
      toggleTracking(false)
    })
    changeTracked(false)

  }, [house, changeTracked, toggleTracking])

  async function submitHandle() {
    await trackProperty(get(config, 'key'))
    toggleSuccess(true)
  }

  useMount(() => {
    if (tracked) {
      stopTrack()
      hideTrackProperty()
    } else {
      trackProperty()
    }
  })

  return (
    <div className='relative px-10 pt-10 sm:px-26 sm:pt-30'>
      <CloseButton className='absolute z-10 right-0 top-0 sm:translate-x-1/3 sm:-translate-y-1/3' circle onClick={hideTrackProperty}></CloseButton>
      {
        success
          ? (
            <>
              <div className='text-font text-xl text-center font-bold'>Success!</div>
              <div className='text-font text-lg text-center mt-16'>Thanks, we will keep you updated with the latest valuation.</div>
              <button className='button-orange w-full mt-30' onClick={hideTrackProperty}>Confirm</button>
            </>
          )
          : (
            <>
              <div className='text-center uppercase text-font-secondary2 text-base'>step 3 of 3</div>
              <div className='text-font text-xl font-medium text-center mt-26'>Why would you like to track this property?</div>
              <div className='text-font-secondary2 text-sm mt-16'>This will help us show you relevant information.</div>
              <div className='text-font text-xl font-medium text-center mt-42'>I am:</div>
              <div className='flex flex-col space-y-16 mt-12'>
                {
                  map(get(data, 'trackType'), item => (
                    <button type='button' className={classNames('button-primary-out h-50 hover:border-primary hover:text-primary', { 'border-neutral text-neutral-5': item.key !== get(config, 'key') })} key={item.key} onClick={() => setConfig(item)}>{item.value}</button>
                  ))
                }
              </div>
              <Form poster={submitHandle}>
                <FormButton buttonTypeClassName='button-orange' buttonClassName='mt-30'>TRACK THIS PROPERTY</FormButton>
              </Form>
            </>
          )
      }

      <MaskLoading loading={isLoading}></MaskLoading>
    </div>
  )
}