'use client'

import httpClient from '@apiClient'
import Form from '@component/form'
import FormButton from '@component/form/button'
import FormCheckbox from '@component/form/checkbox'
import FormInput from '@component/form/input'
// import FormRadio from '@component/form/radio'
import useEnquiry from '@hooks/useEnquiry'
import useFetch from '@hooks/useFetch'
import useLogin from '@hooks/useLogin'
import { enquiryDataLayer } from '@plugin/google/gtm/modules/property'
import { prospectUser } from '@plugin/login'
import { joinStr } from '@utils'
import { getHouseId, isRentData, isVillageData } from '@utils/house'
import { find, first, get, includes, map, pick, range, replace, toLower } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'

export default function FormContent({
  specs,
  type,
}) {

  const { isLogin } = useLogin()
  const { data: user } = useFetch(isLogin ? { url: 'user/info', cfCache: false } : null)
  const enquiryState = useEnquiry()
  const { house, spec, enquirySuccess } = enquiryState
  const isVillage = isVillageData(house)
  const defalutSpecs = spec || find(specs, item => item.value === 'default') || first(specs)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    propertyAddress: get(house, 'address', ''),
    spec: get(defalutSpecs, 'value'),
    body: get(defalutSpecs, 'default', ''),
    isReceiveBizEmail: 1
  })

  useEffect(() => {
    if (user) {
      setFormData(d => ({ ...d, name: joinStr([user.firstName, user.lastName], ' '), email: user.email }))
    }
  }, [user, setFormData])

  // 根据选择的问题 更新body
  // useEffect(() => {
  //   const spec = find(specs, item => item.value === formData.spec)
  //   if (spec && spec.default && spec.default !== formData.body) {
  //     setFormData(d => ({ ...d, body: spec.default || '' }))
  //   }
  // }, [formData, setFormData, specs])

  // push datalayer
  useEffect(() => {
    const label = replace(toLower(get(find(specs, { value: formData.spec }), 'label')), /\s/g, '')
    if (includes(label, 'virtualviewing')) {
      enquiryDataLayer.specs.virtualviewing()
    } else if (includes(label, 'online')) {
      enquiryDataLayer.specs.online()
    } else if (includes(label, 'privateviewing')) {
      enquiryDataLayer.specs.privateviewing()
    }
  }, [formData.spec, specs])

  const rules = {
    name: {
      required: true,
      trigger: 'blur',
      message: 'First name is required'
    },
    email: {
      required: true,
      type: 'email',
      trigger: 'blur',
      message: 'Please enter a valid email address'
    }
  }

  // post params
  const params = useMemo(() => {
    const {
      house,
      agent,
      suburb,
      office,
      brand,
      franchise,
      displayHome,
      signupSource,
      isHouse,
      isAgent,
      isSuburb,
      isOffice,
      isBuilder,
      sendAllAgent
    } = enquiryState
    const params = {
      ...formData,
      type,
      signupSource,
    }
    // type有这几种类型
    // house|rental|agent|office|builder|display_home
    // 房源详情咨询相关参数
    if (isHouse) {
      // https://7thave.atlassian.net/browse/HT-1416
      params.id = getHouseId(house)
      params.type = 'house'
      if (isVillageData(house)) {
        params.toEmail = get(house, 'villageContactEmail')
      } else if (isRentData(house)) {
        params.type = 'rental'
      }
      // https://7thave.atlassian.net/browse/HT-17
      // else if (isPropertyData(house)) {
      //   params.id = get(agent, 'id')
      //   params.type = 'agent'
      //   params.propertyId = get(house, 'propertyId')
      // }
      else {
        if (!sendAllAgent) params.agentId = get(agent, 'id')
      }
    }
    // 中介详情咨询相关参数
    else if (isAgent) {
      params.id = get(agent, 'id')
    }
    // 中介公司详情咨询相关参数
    else if (isOffice) {
      params.type = 'office'
      params.id = get(office, 'id')
    }
    // suburb详情咨询相关参数
    else if (isSuburb) {
      params.id = get(agent, 'id')
      params.suburbId = get(suburb, 'id')
    }
    // builder详情咨询相关参数
    else if (isBuilder) {
      if (displayHome) {
        params.displayHomeId = get(displayHome, 'id')
        params.type = 'display_home'
      } else {
        if (brand) params.builderBrandId = get(brand, 'id')
        if (franchise) params.builderFranchiseId = get(franchise, 'id')
        params.type = 'builder'
      }
    }
    return params
  }, [type, formData, enquiryState])

  const poster = useCallback(async () => {
    await httpClient.post('user/enquiry', params)
    // 注册prospect user
    prospectUser(pick(params, ['email', 'isReceiveBizEmail', 'signupSource']))
    enquirySuccess(formData)
  }, [params, formData, enquirySuccess])

  return (
    <Form data={formData} rules={rules} onChange={setFormData} poster={poster} className='grid grid-cols-1 gap-16 sm:grid-cols-2 sm:gap-24'>
      <div>
        {/* <FormRadio prop='spec' label='What would you like to do?' data={specs} required className='sm:hidden'></FormRadio> */}
        <FormInput prop='name' label='First name'></FormInput>
        <FormInput prop='email' label='Email'></FormInput>
        <FormInput prop='phoneNumber' label='Phone (Optional)'></FormInput>
        {/* {
          !isVillage && (<FormInput prop='propertyAddress' label='Property the enquiry is for'></FormInput>)
        } */}
      </div>
      <div>
        {/* <FormRadio prop='spec' label='What would you like to do?' data={specs} required className='hidden sm:block'></FormRadio> */}
        <FormInput prop='body' label='Message (Optional)' inputClassName='!text-sm' textarea></FormInput>
        <FormCheckbox prop='isReceiveBizEmail' trueValue={1} falseValse={0}>
          {
            isVillage
              ? `Yes, I'd love to be kept up to date with news and events from Metlifecare.`
              : 'I would like to receive emails with property info, updates and more from OneRoof.'
          }
        </FormCheckbox>
        <FormButton buttonTypeClassName='button-orange' className='mt-10'>Contact now</FormButton>
      </div>
    </Form>
  )
}

export function FormSkeleton() {

  return (
    <div className='skeleton-wrap grid grid-cols-1 gap-16 sm:grid-cols-2 sm:gap-24'>
      <div className='space-y-16'>
        {
          map(range(0, 4), idx => (
            <div key={idx}>
              <span className='h-8 w-1/4'></span>
              <span className='h-24 mt-4'></span>
            </div>
          ))
        }
      </div>
      <div className='space-y-16'>
        <span className='h-20 w-1/3'></span>
        <span className='h-100'></span>
        <div className='grid grid-cols-2 gap-x-16'>
          <span className='h-40'></span>
          <span className='h-40'></span>
        </div>
      </div>
    </div>
  )
}