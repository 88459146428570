'use client'

import { useMemo } from 'react'
import { useActionBeforeContext } from './context'
import { map } from 'lodash-es'
import useLogin from '@hooks/useLogin'
import classNames from 'classnames'

export default function ActionBeforeContent({
  autoWidth,
  children
}) {

  const { show, currentStep, steps, top } = useActionBeforeContext()
  const { isLogin } = useLogin()
  const viewChildren = useMemo(() => {
    return isLogin || (!steps[currentStep])
  }, [isLogin, steps, currentStep])
  const View = useMemo(() => {
    if (!show) return
    if (viewChildren) return children

    if (steps[currentStep]) {
      const content = steps[currentStep]
      return (
        <>
          {top}
          {content}
          <div className='hidden'>
            {map(steps, (item, idx) => (
              <div key={idx}>{item}</div>
            ))}
          </div>
        </>
      )
    }
  }, [show, currentStep, steps, top, children, viewChildren])
  return (
    <div className={classNames('bg-white w-full max-h-fill-screen overflow-auto sm:rounded-sm sm:h-auto sm:overflow-visible', autoWidth && viewChildren ? 'sm:w-auto' : 'sm:w-360 pb-10 sm:pb-26')}>
      {View}
    </div>
  )
}