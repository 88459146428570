'use client'

import httpClient from '@apiClient'
import Form from '@component/form'
import FormButton from '@component/form/button'
import FormInput from '@component/form/input'
import { favouriteDataLayer } from '@plugin/google/gtm/modules/property'
import { isFunction } from 'lodash-es'
import { useCallback, useState } from 'react'
import { useMount } from 'react-use'

export default function NewFolder({
  name,
  houseId,
  onClose
}) {

  // form data
  const [formData, setFormData] = useState({
    name,
    email: ''
  })

  // 表单验证规则
  const rules = {
    name: {
      required: true,
      trigger: 'blur',
      message: 'Name is required'
    },
    email: {
      type: 'email',
      trigger: 'blur',
      message: 'Please enter a valid email address'
    }
  }

  // 表单提交函数
  const poster = useCallback(async () => {
    const { folderId } = await httpClient.post('folder', formData)
    await httpClient.post('folder/item', { houseId, folderIds: folderId })
    favouriteDataLayer.newFolderSuccess()
    isFunction(onClose) && onClose()
  }, [formData, houseId, onClose])

  useMount(() => {
    favouriteDataLayer.newFolder()
  })

  return (
    <>
      <div className='text-xl text-font mb-16'>Create a new list</div>
      <Form size='sm' data={formData} rules={rules} poster={poster} onChange={setFormData}>
        <FormInput label='List name' required prop='name' placeholder='Name your list'></FormInput>
        <FormInput label='Share this list' prop='email' placeholder='Invite by email'></FormInput>
        <div className='grid grid-cols-2 items-end gap-x-10'>
          <button type='button' className='button-primary-out' onClick={onClose}>Cancel</button>
          <FormButton>Create</FormButton>
        </div>
      </Form>
    </>
  )
}