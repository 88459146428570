'use client'

import ActiveLink from '@component/activeLink'
import { useOpenSigninDialog, useOpenSignupDialog } from '@component/login/context'
import { LAST_ESTIMATE_MAP_SLUG_KEY } from '@config'
import useLogin from '@hooks/useLogin'
import getRouter, { estimateMap, URLS } from '@router'
import { stopPropagation } from '@utils'
import classNames from 'classnames'
import { map, size } from 'lodash-es'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { useHeaderContext } from '../context'
import HeaderMenuPopup from '../popup'

export function NavMenuPopup() {
  const { isLogin } = useLogin()
  const openSigninDialog = useOpenSigninDialog()
  const openSignupDialog = useOpenSignupDialog()
  const { showNavMenu, toggleNavMenu } = useHeaderContext()
  const [currentExpand, setCurrentExpand] = useState(null)
  const toggleExpand = useCallback((label) => {
    return () => {
      setCurrentExpand(label === currentExpand ? null : label)
    }
  }, [currentExpand, setCurrentExpand])

  // 估价地图最后一次使用的slug
  const [estimateHref, setEstimateHref] = useState(getRouter(URLS.estimateHomepage))
  useMount(() => {
    setTimeout(() => {
      const lastEstimateSlug = JSON.parse(localStorage.getItem(LAST_ESTIMATE_MAP_SLUG_KEY))
      if (lastEstimateSlug && lastEstimateSlug.slug) {
        setEstimateHref(estimateMap(lastEstimateSlug.slug, lastEstimateSlug.searchParams))
      }
    }, 0)
  })

  const NavMenu = useMemo(() => [
    {
      label: 'Find a property',
      children: [
        { label: 'Residential', href: getRouter(URLS.homepage), home: true },
        { label: 'New Homes', href: getRouter(URLS.newhomespage) },
        { label: 'Commercial', href: getRouter(URLS.commercialHomepage) },
        { label: 'Rural', href: getRouter(URLS.ruralHomepage) },
        { label: 'Businesses', href: getRouter(URLS.businessesHomepage) },
        { label: 'Rental', href: getRouter(URLS.rentHomepage) }
      ]
    },
    {
      label: 'Estimates',
      href: estimateHref
    },
    {
      label: 'News',
      href: getRouter(URLS.newsHomepage)
    },
    {
      label: 'Tools',
      children: [
        { label: 'Find an agent', href: getRouter(URLS.agentHomepage) },
        { label: 'Find a sold property', href: getRouter(URLS.soldHomepage) },
        { label: 'Find a suburb', href: getRouter(URLS.suburbHomepage) },
        { label: 'First home buyers', href: getRouter(URLS.firsthomebuyers) },
        { label: 'Property report', href: getRouter(URLS.propertyReport) },
        { label: 'House price report', href: getRouter(URLS.housePriceReport) },
        { label: 'Home sellers guide', href: getRouter(URLS.sellersGuide) },
        { label: 'New-builds guide', href: getRouter(URLS.newBuildsGuide) },
        { label: 'Homes We Love', href: getRouter(URLS.homesWeLove) }
      ]
    }
  ], [estimateHref])

  return (
    <HeaderMenuPopup contentLeft show={showNavMenu} toggleShow={toggleNavMenu}>
      <div
        className={classNames(
          'bg-white w-[70vw] h-full overflow-auto overscroll-contain transition-transform flex flex-col',
          showNavMenu ? 'translate-x-0' : '-translate-x-full'
        )}
        onClick={stopPropagation}
      >
        <Suspense>
          {
            map(NavMenu, item => (
              <NavRow
                data={item}
                key={item.label}
                currentExpand={currentExpand}
                toggleExpand={toggleExpand}
              />
            ))
          }
        </Suspense>
        {
          !isLogin && (
            <div className='p-16 flex items-center gap-x-20'>
              <button className='button-text-primary' onClick={openSigninDialog}>Sign in</button>
              <span className=''>|</span>
              <button className='button-text-primary' onClick={openSignupDialog}>Sign up</button>
            </div>
          )
        }
        <div className='flex-1 -mb-1'></div>
      </div>
    </HeaderMenuPopup>
  )
}

function NavRow({
  data,
  currentExpand,
  toggleExpand
}) {
  const { toggleNavMenu } = useHeaderContext()
  const pathname = usePathname()

  const hasChildren = size(data.children) > 0
  const childrenRef = useRef(null)
  const [childrenHeight, setChildrenHeight] = useState(0)
  const showChildren = currentExpand === data.label

  useMount(() => {
    childrenRef.current && setChildrenHeight(childrenRef.current.offsetHeight)
  })

  return (
    <div className='border-b'>
      {
        hasChildren
          ? (
            <>
              <div className='h-50 px-16 flex items-center justify-between font-medium' onClick={toggleExpand(data.label)}>
                {data.label}
                <i className={classNames(
                  'icon icon-arrow-down transition-transform',
                  { '-rotate-180': showChildren }
                )} />
              </div>
              <div className={classNames('overflow-hidden transition-[height]', { '-mt-10': showChildren })} style={{ height: `${showChildren ? childrenHeight : 0}px` }}>
                <div ref={childrenRef}>
                  {
                    map(data.children, item => (
                      <ActiveLink
                        href={item.href}
                        key={item.label}
                        className='block px-16 py-10'
                        activeClassName={classNames({ 'text-primary bg-primary-light': item.home ? pathname === '/' : true })}
                        onClick={toggleNavMenu}
                      >
                        - {item.label}
                      </ActiveLink>
                    ))
                  }
                </div>
              </div>
            </>
          )
          : (
            <Link
              href={data.href}
              className='h-50 px-16 flex items-center justify-between font-medium'
              onClick={toggleNavMenu}
            >
              {data.label}
            </Link>
          )
      }
    </div>
  )
}
