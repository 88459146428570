'use client'

import CloseButton from '@component/closeButton'
import IconLogo from '@icon/const/logo-inline.svg'
import { getFullPath } from '@utils'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useSessionStorage } from 'react-use'

export default function OpenApp() {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const url = getFullPath(pathname)
  const isShowOpen = searchParams.get('openApp') === 'hidden'
  const isFromApp = searchParams.get('from') === 'app'

  const [visible, setVisible] = useState(false)
  const [show, setShow] = useSessionStorage('open-app-banner', true)
  useEffect(() => setVisible(show && !isShowOpen && !isFromApp), [show, setVisible, isShowOpen, isFromApp])

  return visible && (
    <div className='sm:hidden bg-font relative z-[101] border-b h-50 pl-12 pr-6 gap-x-6 flex items-center' id="open-app-banner">
      <IconLogo></IconLogo>
      <div className='mr-auto text-xs text-white'>
        <strong>OneRoof</strong>
        <div>Open in the OneRoof app</div>
      </div>
      <a href={getFireBaseLink(url)} className='w-70 h-25 inline-flex items-center justify-center bg-white rounded-full uppercase text-xs' data-noprogress>open</a>
      <CloseButton iconClassName='!text-white' onClick={() => setShow(false)}></CloseButton>
    </div>
  )
}

function getFireBaseLink(url) {
  return `https://r6c3w.app.goo.gl/?link=${url}&apn=com.nzme.oneroof&afl=https://www.oneroof.co.nz/download&ibi=com.hougarden.iHomes&isi=1336312690&ius=iOSOfOneRoof&efr=1`
}
