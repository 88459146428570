'use client'

import httpClient from '@apiClient'
import CloseButton from '@component/closeButton'
import Form from '@component/form'
import FormButton from '@component/form/button'
import FormCheckbox from '@component/form/checkbox'
import FormInput from '@component/form/input'
import ImageBgLoading from '@component/imageBgLoading'
import { HOUSE_TYPE_ID, NEW_ZEALAND } from '@config'
import useSaveSearch from '@hooks/useSaveSearch'
import ImageSaveSearchBg from '@image/house/save-search-bg.jpg'
import { saveSearchDataLayer } from '@plugin/google/gtm/modules/houseList'
import { toNumber } from '@utils'
import { isCommercialData } from '@utils/house'
import { isFunction, join } from 'lodash-es'
import { split } from 'lodash-es'
import { size } from 'lodash-es'
import { get, map } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'

export default function SaveSearchContent() {

  const { data, suburbs, hideSaveSearch, successCallback } = useSaveSearch()
  const { typeId } = data
  const price = useMemo(() => {
    if (!data.price) {
      return
    }
    return decodeURIComponent(data.price)
  }, [data])

  const conditions = useMemo(() => {
    const res = []
    const typeLabel = {
      [HOUSE_TYPE_ID.residential]: 'Buy',
      [HOUSE_TYPE_ID['commercial-property-for-lease']]: 'Commercial for sale',
      [HOUSE_TYPE_ID.rural]: 'Rural',
      [HOUSE_TYPE_ID['businesses-for-sale']]: 'Businesses for sale',
      [HOUSE_TYPE_ID.rent]: 'Rent',
      [HOUSE_TYPE_ID['commercial-property-for-lease']]: 'Commercial for lease',
      [HOUSE_TYPE_ID.sold]: 'Sold'
    }

    // 搜索类型
    res.push(get(typeLabel, typeId, 'Buy'))
    // 搜索价格
    res.push(price ? `${price} price` : 'Any price')
    // 搜索城区
    if (size(suburbs)) {
      size(suburbs) === 1
        ? res.push(get(suburbs, '[0].pureLabel') || get(suburbs, '[0].label', ''))
        : res.push(`${get(suburbs, '[0].pureLabel') || get(suburbs, '[0].label', '')}, ${size(suburbs) - 1} more`)
    } else {
      res.push(NEW_ZEALAND.label)
    }

    if (!isCommercialData({ typeId })) {
      // 搜索卧室
      res.push(`${getFacility(data.bedrooms)} beds`)
      // 搜索卫浴
      res.push(`${getFacility(data.bathrooms)} baths`)
      // 搜索车位
      res.push(`${getFacility(data.carspaces)} parking`)
    }

    return res
  }, [data, typeId, suburbs, price])
  const [formData, setFormData] = useState({
    name: `${get(suburbs, '[0].pureLabel') || get(suburbs, '[0].label') || NEW_ZEALAND.label} properties`,
    emailAlert: 1
  })
  // poster
  const poster = useCallback(async () => {
    const { id } = await httpClient.post('/user/criteria', { ...data, ...formData, price })
    hideSaveSearch()
    isFunction(successCallback) && successCallback(id)
    saveSearchDataLayer.success()
  }, [data, formData, hideSaveSearch, successCallback, price])

  return (
    <div className='relative max-w-full sm:w-574 sm:rounded-sm overflow-hidden'>
      <CloseButton className='absolute z-10 right-10 top-10' iconClassName='!text-white sm:!text-primary' onClick={hideSaveSearch}></CloseButton>
      <div className='relative'>
        <ImageBgLoading src={ImageSaveSearchBg} width={574} height={142} className='w-full object-cover block' alt='save this search'></ImageBgLoading>
        <div className='absolute inset-x-0 inset-y-0 bg-image-mask-sm flex items-end justify-center text-white p-16 text-xl'>
          Save this search
        </div>
      </div>
      <Form data={formData} onChange={setFormData} poster={poster} className='p-16 sm:p-24 flex flex-col sm:grid sm:grid-cols-2 gap-x-16 gap-y-10'>
        <FormInput label='Search name' prop='name' noError></FormInput>
        <FormCheckbox prop='emailAlert' trueValue={1} falseValse={0} className='order-5 sm:order-none'>Receive email alerts</FormCheckbox>
        <div className='grid grid-cols-2 items-end gap-x-10 order-6 sm:order-none'>
          <button type='button' className='button-primary-out' onClick={hideSaveSearch}>Cancel</button>
          <FormButton buttonTypeClassName='button-orange'>Save search</FormButton>
        </div>
        <div className='sm:col-start-1 sm:row-start-1 sm:row-end-4'>
          <div className='text-font text-sm'>Search criteria</div>
          <ul className='mt-8 space-y-8'>
            {
              map(conditions, item => (
                <li key={item} className='text-neutral-5 text-sm'>- {item}</li>
              ))
            }
          </ul>
        </div>
      </Form>
    </div>
  )
}

function getFacility(text) {
  if (!text) {
    return 'Any'
  }

  const list = map(split(text, ','), item => {
    if (toNumber(item) === 0) {
      return 'S'
    } else if (toNumber(item) === 5) {
      return '5+'
    } else {
      return item
    }
  })

  return join(list, ',')
}