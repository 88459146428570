import { isUndefined } from 'lodash-es'
import { createContext, useContext, useEffect, useState } from 'react'
import { useBoolean } from 'react-use'

export const ActionBeforeContext = createContext()

export function useActionBeforeContext() {
  return useContext(ActionBeforeContext)
}

export function ActionBeforeContextProvider({
  state,
  children
}) {

  const { show, step } = state
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    isReceiveBizEmail: 1,
    remember: true
  })
  const [currentStep, setSteps] = useState(step)
  const [registered, toggleRegistered] = useBoolean(false)

  function next(data = {}, isRegistered) {
    if (!isUndefined(isRegistered)) toggleRegistered(isRegistered)
    setSteps(currentStep + 1)
    setFormData(d => ({ ...d, ...data }))
  }
  function prev() {
    setSteps(currentStep - 1)
  }
  function loginDone() {
    setSteps(currentStep + 2)
  }

  useEffect(() => {
    if (!show) setSteps(step)
  }, [show, step, setSteps])

  return (
    <ActionBeforeContext.Provider
      value={{
        ...state,
        formData,
        registered,
        currentStep,
        setFormData,
        toggleRegistered,
        setSteps,
        prev,
        next,
        loginDone
      }}
    >
      {children}
    </ActionBeforeContext.Provider>
  )
}