'use client'

import httpClient from '@apiClient'
import IconLeft from '@icon/email-signup-left.svg'
import IconMobile from '@icon/email-signup-mobile.svg'
import IconRight from '@icon/email-signup-right.svg'
import { getUUID } from '@plugin/login'
import { useEffect, useState } from 'react'
import EmailSignUpForm, { SUBSCRIBE_LATEST_NEWS_KEY } from './form'

export default function EmailSignUp() {

  const [showSubscribe, setShowSubscribe] = useState(false)
  useEffect(() => {
    if (sessionStorage.getItem(SUBSCRIBE_LATEST_NEWS_KEY)) {
      return
    }

    const uuid = getUUID()
    httpClient.get(`email-unsubscribe/${uuid}`)
      .catch(() => [])
      .then(data => {
        if (data.length === 0) {
          setShowSubscribe(true)
        } else {
          sessionStorage.setItem(SUBSCRIBE_LATEST_NEWS_KEY, true)
        }
      })
  }, [setShowSubscribe])

  if (!showSubscribe) {
    return null
  }

  return (
    <section className='px-[var(--container-pd)] pb-27 md:px-0 md:pb-0'>
      <section className='bg-neutral-1 relative py-36 md:py-25 rounded md:rounded-none h-302 md:h-auto'>
        <IconLeft
          className='absolute top-0 left-0 h-full w-auto fill-white pointer-events-none z-0 hidden md:block'
        />
        <IconRight
          className='absolute top-0 right-0 h-full w-auto fill-white pointer-events-none z-0 hidden md:block'
        />
        <IconMobile
          className='absolute inset-0 w-full h-full fill-white pointer-events-none z-0 md:hidden'
        />
        <EmailSignUpForm />
      </section>
    </section>
  )
}