'use client'

import ActionBefore, { ActionSekelton, TopSekelton } from '@component/pages/actionBefore'
import useTrackProperty from '@hooks/useTrackProperty'
import IconTrackProperty from '@image/house/trackProperty.jpg'
import { trackDataLayer } from '@plugin/google/gtm/modules/property'
import { get } from 'lodash-es'
import dynamic from 'next/dynamic'
import { useUnmount } from 'react-use'
import TrackPropertyContent from './content'
const loading = () => <ActionSekelton></ActionSekelton>
const StepTop = dynamic(() => import('@component/pages/actionBefore/steps/top'), { ssr: false, loading: () => <TopSekelton></TopSekelton> })
const StepEmail = dynamic(() => import('@component/pages/actionBefore/steps/email'), { ssr: false, loading })
const StepPassword = dynamic(() => import('@component/pages/actionBefore/steps/password'), { ssr: false, loading })

export default function TrackProperty() {

  const { show, house, signupSource, hideTrackProperty } = useTrackProperty()
  useUnmount(() => {
    hideTrackProperty()
  })

  return (
    <ActionBefore
      show={show}
      signupSource={signupSource}
      steps={[
        <StepEmail
          title='Enter your email to receive free valuations'
          key='step-email'
          signinClick={trackDataLayer.signin}
          onRegister={registered => registered ? trackDataLayer.registered() : trackDataLayer.notRegistered()}
        ></StepEmail>,
        <StepPassword
          signinText={<>Sign in to your account to<br />track this property.</>}
          signupTitle='You are almost there!'
          signupText={<>Create a password to track<br />this property</>}
          key='step-password'
        ></StepPassword>
      ]}
      onClose={hideTrackProperty}
      top={
        <StepTop
          cover={get(house, 'images.0')}
          lat={get(house, 'lat')}
          lng={get(house, 'lng')}
          zoom={20}
          boundaries={get(house, 'boundaries')}
          icon={IconTrackProperty}
          iconSize={56}
          iconClassName='bg-white'
          onClose={hideTrackProperty}
          count={3}
        ></StepTop>
      }
    >
      <TrackPropertyContent></TrackPropertyContent>
    </ActionBefore>
  )
}
