'use client'

import Avatar from '@component/avatar'
import IconProfile from '@icon/profile.svg'
import AvatarPopup from './popup'
import { useOpenSigninDialog } from '@component/login/context'
import useLogin from '@hooks/useLogin'
import useGoogleLogin from '@hooks/useGoogleLogin'
import { login } from '@store/index'
import { useRecoilValue } from 'recoil'
import { joinStr } from '@utils'
import { useCallback, useEffect } from 'react'
import { loginDataLayer } from '@plugin/google/gtm/modules/login'
import Link from 'next/link'
import { houseList } from '@router'
import { houseSearchType } from '@routerConfig'
import getHouseRoute from '@plugin/searchRoute/modules/house'

export default function UserAvatar() {

  const { user, info, loaded, googleSignin, isLogin } = useLogin()
  const openSigninDialog = useOpenSigninDialog('Web_Nav')
  const openSigninHandle = useCallback(() => {
    loginDataLayer.signin.buttonClick()
    openSigninDialog()
  }, [openSigninDialog])
  const { signin, signup, forgot } = useRecoilValue(login)
  const { googleLoginChildren, cancel } = useGoogleLogin({
    resize: false,
    async onSuccess(data) {
      await googleSignin(data, 'OneTap_site')
    }
  })

  // 如果已经登录，取消google.id
  useEffect(() => {
    if ((isLogin || info) && cancel) cancel()
  }, [cancel, info, isLogin])

  if (!loaded) return

  if (!user) {
    return (
      <>
        <AvatarContainer>
          <div className='w-30 h-30 flex items-center justify-center sm:hidden' onClick={openSigninHandle}>
            <IconProfile width={19} height={19} />
          </div>
          <div className='hidden text-sm cursor-pointer sm:block' onClick={openSigninHandle}>Sign in</div>
        </AvatarContainer>
        {/* 如果有登录/注册弹窗出现，这里不要加载google.id
        不能多次使用initialize
        https://developers.google.com/identity/gsi/web/reference/js-reference?hl=zh-cn#google.accounts.id.initialize */}
        {!(signin || signup || forgot) && googleLoginChildren}
      </>
    )
  }

  const name = joinStr([user.firstName, user.lastName], ' ')
  return (
    <AvatarContainer>
      <AvatarPopup user={user}>
        <Avatar circle src={user.avatar} alt={name} width={48} height={48} className='!w-32 !h-32 lg:!w-48 lg:!h-48' defaultIcon='icon-avatar'></Avatar>
      </AvatarPopup>
    </AvatarContainer>
  )
}

function AvatarContainer({
  children,
  ...props
}) {
  return (
    <div className='min-w-0 absolute right-container-pd top-10 sm:static' {...props}>
      <div className='flex items-center gap-x-20 lg:gap-x-30'>
        <SearchBar></SearchBar>
        {children}
      </div>
    </div>
  )
}

function SearchBar() {
  const houseRoute = getHouseRoute()
  houseRoute.pushNewZealand()
  return (
    <Link
      prefetch={false}
      href={houseList(houseSearchType.residential, houseRoute.toSlug())}
      className='relative flex items-center pr-20 lg:pr-30 after:w-1 after:h-20 after:bg-neutral-3 after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2'
    >
      <i className='icon icon-search !text-xl lg:!text-base'></i>
      <span className='hidden lg:block text-sm lg:text-base text-primary ml-8'>Search properties</span>
    </Link>
  )
}