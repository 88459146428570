import { trackProperty } from '@store/index'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useCallback } from 'react'
import { trackDataLayer } from '@plugin/google/gtm/modules/property'

export default function useTrackProperty() {

  const state = useRecoilValue(trackProperty)
  const setTrackPropertyState = useSetRecoilState(trackProperty)

  // 认领房源
  const showTrackProperty = useCallback(({ house, signupSource }) => {
    return event => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      // push event
      trackDataLayer.show()
      setTrackPropertyState(d => ({ ...d, show: true, house, signupSource }))
    }
  }, [setTrackPropertyState])

  // 缓存房源tracked
  const changeTracked = useCallback(tracked => {
    const { house, trackedList } = state
    const newTrackedList = { ...trackedList, [house.propertyId]: tracked }
    setTrackPropertyState(d => ({ ...d, trackedList: newTrackedList }))
  }, [setTrackPropertyState, state])

  // 缓存房源tracked
  const changeHouseTracked = useCallback((house, tracked = true) => {
    const { trackedList } = state
    const newTrackedList = { ...trackedList, [house.propertyId]: tracked }
    setTrackPropertyState(d => ({ ...d, trackedList: newTrackedList }))
  }, [setTrackPropertyState, state])

  // tracking
  const toggleTracking = useCallback(tracking => {
    setTrackPropertyState(d => ({ ...d, tracking }))
  }, [setTrackPropertyState])

  // 隐藏track
  const hideTrackProperty = useCallback(() => {
    setTrackPropertyState(d => ({ ...d, show: false }))
  }, [setTrackPropertyState])

  return {
    ...state,
    showTrackProperty,
    changeTracked,
    changeHouseTracked,
    toggleTracking,
    hideTrackProperty
  }
}