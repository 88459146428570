'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import Collapse from '@component/collapse'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { isFunction, isUndefined } from 'lodash-es'
import getRouter, { URLS, houseList } from '@router'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { houseSearchType } from '@routerConfig'
import { NEW_ZEALAND } from '@config'
import { estimateMapSuburb } from '@router'

export default function Links() {

  const [opens, setOpens] = useState({})
  function changeHandle(key) {
    return (v) => {
      setOpens(d => ({ ...d, [key]: v }))
    }
  }

  return (
    <section className='grid gap-y-4 mt-28 sm:mt-56 sm:justify-between sm:grid-cols-[repeat(5,auto)]'>
      <Group title='Listings' onChange={changeHandle('listings')}>
        <Item href={getListingsRoute('Northland', 34)}>Northland</Item>
        <Item href={getListingsRoute('Auckland', 35)}>Auckland</Item>
        <Item href={getListingsRoute('Bay of Plenty', 37)}>Bay of Plenty</Item>
        <Item href={getListingsRoute('Waikato', 36)}>Waikato</Item>
        <Item href={getListingsRoute('Coromandel', 48)}>Coromandel</Item>
        <Item href={getListingsRoute('Gisborne Region', 38)}>Gisborne Region</Item>
        <Item href={getListingsRoute('Central North Island', 55)}>Central North Island</Item>
        <Item href={getListingsRoute('Hawke’s Bay', 39)}>Hawke’s Bay</Item>
        <Item href={getListingsRoute('Taranaki', 40)}>Taranaki</Item>
        <Item href={getListingsRoute('Manawatu/Whanganui', 56)}>Manawatu/Whanganui</Item>
      </Group>
      <Group open={opens['listings']}>
        <Item href={getListingsRoute('Wairarapa', 52)}>Wairarapa</Item>
        <Item href={getListingsRoute('Wellington', 42)}>Wellington</Item>
        <Item href={getListingsRoute('Marlborough', 51)}>Marlborough</Item>
        <Item href={getListingsRoute('Nelson Bays', 43)}>Nelson Bays</Item>
        <Item href={getListingsRoute('West Coast', 44)}>West Coast</Item>
        <Item href={getListingsRoute('Canterbury', 45)}>Canterbury</Item>
        <Item href={getListingsRoute('Central Otago/Lakes District', 50)}>Central Otago/Lakes District</Item>
        <Item href={getListingsRoute('Otago', 46)}>Otago</Item>
        <Item href={getListingsRoute('Southland', 47)}>Southland</Item>
        <Item href={getListingsRoute('Pacific Islands', 54)}>Pacific Islands</Item>
      </Group>
      <Group title='Explore'>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id)}>Residential for sale</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.rent)}>Residential for rent</Item>
        <Item href={getEstimateMapRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, NEW_ZEALAND.level)}>Property estimates</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.sold)}>Sold properties</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.commercialForSale)}>Commercial for sale</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.commercialForLease)}>Commercial for lease</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.business)}>Businesses for sale</Item>
        <Item href={getRouter(URLS.agentHomepage)}>Find an agent</Item>
        <Item href={getListingsRoute(NEW_ZEALAND.label, NEW_ZEALAND.id, houseSearchType.rural)}>Rural properties</Item>
        <Item href={getRouter(URLS.newsHomepage)}>News</Item>
      </Group>
      <Group title='Account'>
        <Item href={getRouter(URLS.profile)}>Profile</Item>
        <Item href={getRouter(URLS.profileLists)}>Saved properties</Item>
        <Item href={getRouter(URLS.profileSavedSearch)}>Saved searches</Item>
        <Item href={getRouter(URLS.profileJourney)}>Open homes planner</Item>
        <Item href={getRouter(URLS.profileNotification)}>Manage notifications</Item>
      </Group>
      <Group title='Contact us'>
        <Item className='sm:max-w-164'>Private Bag 92198, Victoria St West, Auckland 1142, New Zealand</Item>
        <Item href='mailto:support@oneroof.zendesk.com'>Contact OneRoof support</Item>
        <Item href='mailto:sales@oneroof.co.nz'>Contact OneRoof sales</Item>
      </Group>
    </section>
  )
}

export function Group({
  title,
  children,
  onChange,
  open
}) {

  const [show, setShow] = useState(false)
  function toggleHandle() {
    setShow(!show)
    isFunction(onChange) && onChange(!show)
  }
  useEffect(() => {
    if (isUndefined(open)) return

    if (open !== show) {
      setShow(open)
    }
  }, [open, show, setShow])
  return (
    <div>
      <div className={classNames('flex items-center justify-between py-6', { 'hidden sm:flex': !title })} onClick={toggleHandle}>
        <span className='text-neutral text-sm sm:text-xl' dangerouslySetInnerHTML={{ __html: title || '&nbsp;' }}></span>
        <i className={classNames('icon text-neutral icon-arrow-down !text-sm transition-transform sm:hidden', { 'rotate-180': show })}></i>
      </div>
      <Collapse show={show} className='sm:overflow-visible sm:!h-auto'>
        <div className='pt-12'>{children}</div>
      </Collapse>
    </div>
  )
}

export function Item({
  href,
  children,
  className,
  scroll = true
}) {

  const styles = classNames('block text-sm mt-12 first:mt-0', className)
  return (
    href
      ? <DisablePrefetchLink href={href} prefetch={false} scroll={scroll} className={styles}>{children}</DisablePrefetchLink>
      : <div className={styles}>{children}</div>
  )
}

/**
 * 获取房源列表链接
 * @param {string} label suburb label
 * @param {number} id suburb id
 * @param {string} searchType search type
 * @param {string} level suburb level
 * @returns house list href
 */
function getListingsRoute(label, id, searchType = houseSearchType.residential, level = 'region') {
  const houseRoute = getHouseRoute()
  houseRoute.setSuburbs({ label, id, level })
  return houseList(searchType, houseRoute.toSlug())
}

/**
 * 获取估价地图链接
 * @param {string} label suburb label
 * @param {number} id suburb id
 * @param {string} level suburb level
 * @returns estimate map href
 */
function getEstimateMapRoute(label, id, level = 'region') {
  return estimateMapSuburb({ label, id, level })
}