'use client'

import { joinStr } from '@utils'
import { size } from 'lodash-es'
import { useSearchParams } from 'next/navigation'
import { Suspense } from 'react'

export default function FromAppHidden({
  selectors = []
}) {
  return (
    <Suspense>
      <FromAppHiddenContent selectors={selectors} />
    </Suspense>
  )
}

function FromAppHiddenContent({
  selectors
}) {
  const searchParams = useSearchParams()
  if (searchParams.get('from') === 'app' && size(selectors) > 0) {
    return <style dangerouslySetInnerHTML={{ __html: `${joinStr(selectors, ',')}{display: none !important;}` }}></style>
  }
}