'use client'

import { useSearchParams } from 'next/navigation'
import { Suspense, useMemo } from 'react'

export default function ABTest({
  children,
  newComponent,
  useSuspense = true
}) {

  if (useSuspense) {
    return (
      <Suspense>
        <ABTestContent newComponent={newComponent}>
          {children}
        </ABTestContent>
      </Suspense>
    )
  }

  return (
    <ABTestContent newComponent={newComponent}>
      {children}
    </ABTestContent>
  )
}

export function usePageAlt() {
  const searchParams = useSearchParams()
  return useMemo(() => {
    return searchParams.get('page') === 'alt'
  }, [searchParams])
}

function ABTestContent({
  children,
  newComponent,
}) {
  const isPageAlt = usePageAlt()

  if (isPageAlt) {
    return newComponent
  }

  return children
}