'use client'

import AppendRoot from '@component/appendRoot'
import FullPopup from '@component/fullPopup'
import { ActionBeforeContextProvider } from './context'
import ActionBeforeContent from './content'
import { useChangeLoginData } from '@component/login/context'
import { useEffect } from 'react'

export default function ActionBefore({
  top,
  children,
  show = false,
  signupSource = null,
  steps = [],
  step = 0,
  onClose,
  autoWidth = false
}) {

  const changeLoginData = useChangeLoginData()
  useEffect(() => {
    changeLoginData({ signupSource })
  }, [signupSource, changeLoginData])
  return (
    <AppendRoot>
      <FullPopup show={show} smCenter maskCloseable contentClassName='!bg-transparent p-16 w-screen h-fill-screen flex items-center justify-center'>
        <ActionBeforeContextProvider state={{ top, show, signupSource, steps, step, onClose }}>
          <ActionBeforeContent autoWidth={autoWidth}>
            {children}
          </ActionBeforeContent>
        </ActionBeforeContextProvider>
      </FullPopup>
    </AppendRoot>
  )
}

export function ActionSekelton() {
  return (
    <div className='skeleton-wrap'>
      <div className='px-16 py-30'>
        <span className='h-16 w-1/4'></span>
        <span className='h-40 mt-10'></span>
        <span className='h-16 w-1/3 mt-20'></span>
        <span className='h-40 mt-10'></span>
        <span className='h-16 w-1/2 mt-20'></span>
        <span className='h-40 mt-10'></span>
      </div>
    </div>
  )
}

export function TopSekelton() {
  return (
    <div className='skeleton-wrap'>
      <span className='h-124'></span>
    </div>
  )
}