import { pushDataLayer } from '../index'

export const loginDataLayer = {
  signin: {
    buttonClick() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Sign in',
        'event_interaction': 'Modal',
        'event_type': 'Step 1'
      })
    },

    success() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Sign in',
        'event_interaction': 'Modal',
        'event_type': 'Sign in success'
      })
    },

    newUser() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Registration success',
        'event_interaction': 'Modal',
        'event_type': 'Register'
      })
    }
  },

  signup: {
    success() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Register',
        'event_interaction': 'Modal',
        'event_type': 'Registration success'
      })
    }
  }
}