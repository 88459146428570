import { getToken } from '@plugin/login'
import { pushDataLayer } from '../index'
import { PageViewLayer } from '@component/dataLayer'
import { cond, constant, filter, find, map, size } from 'lodash-es'
import { houseSearchType } from '@routerConfig'
import getHouseRoute from '@plugin/searchRoute/modules/house'
import { joinStr, slugToId, toNumber } from '@utils'
import http from '@api'
import { HOUSE_FILTER_ALIAS } from '@config'

function caseType(routerType) {
  return (type, text) => {
    return [constant(routerType === type), constant(text)]
  }
}
export default async function PageViewDataLayer({
  type,
  slug
}) {

  const houseRoute = getHouseRoute()
  houseRoute.fromSlug(slug)

  const compareType = caseType(type)
  const vertical = cond([
    compareType(houseSearchType.residential, 'buy'),
    compareType(houseSearchType.rent, 'rent'),
    compareType(houseSearchType.sold, 'sold'),
    compareType(houseSearchType.newHomes, 'new homes'),
    compareType(houseSearchType.commercialForSale, 'commercial'),
    compareType(houseSearchType.commercialForLease, 'commercial'),
    compareType(houseSearchType.business, 'business'),
    compareType(houseSearchType.rural, 'rural'),
  ])()

  const verticalType = cond([
    compareType(houseSearchType.residential, 'buy'),
    compareType(houseSearchType.rent, 'rent'),
    compareType(houseSearchType.sold, 'sold'),
    compareType(houseSearchType.newHomes, houseRoute.isLandHome ? 'house & land' : 'new apartments & terrace housing'),
    compareType(houseSearchType.commercialForSale, 'commercial for sale'),
    compareType(houseSearchType.commercialForLease, 'commercial for lease'),
    compareType(houseSearchType.business, 'businesses for sale'),
    compareType(houseSearchType.rural, 'rural'),
  ])()

  let propertyTypeLabel = ''
  if (size(houseRoute['property-type'])) {
    const filterMeta = await http.get('houses/filter/meta', { typeId: 1 })
    const propertyType = find(filterMeta, item => HOUSE_FILTER_ALIAS.propertyType.includes(item.alias))
    if (propertyType) {
      const ids = map(houseRoute['property-type'], item => toNumber(slugToId(item)))
      const d = filter(propertyType.children, item => ids.includes(toNumber(item.value)))
      propertyTypeLabel = joinStr(map(d, 'label'), ',')
    }
  }

  return (
    <PageViewLayer clear data={{
      'event': 'page_view',
      'vertical': vertical,
      'vertical_type': verticalType,
      'page_type': 'search',
      'price_from': houseRoute['price-from'],
      'price_to': houseRoute['price-to'],
      'beds': joinStr(houseRoute.bedroom, ','),
      'baths': joinStr(houseRoute.bathroom, ','),
      'property_type': propertyTypeLabel,
      'parking': joinStr(houseRoute.carspaces, ','),
      'surrounding_suburbs': houseRoute.surround ? 'T' : 'F',
      'virtual_viewings': 'F'
    }}></PageViewLayer>
  )
}

export const saveSearchDataLayer = {

  click() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property Search',
      'event_interaction': 'Search results page',
      'event_type': 'Save search'
    })
    const token = getToken()
    if (token) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Save Property Search',
        'event_interaction': 'Modal',
        'event_type': 'Save this search'
      })
    }
  },

  show() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Enter email'
    })
  },

  signin() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to save this search'
    })
  },

  registered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Sign in to save this search'
    })
  },

  notRegistered() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property',
      'event_interaction': 'Modal',
      'event_type': 'Create password'
    })
  },

  success() {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Save Property Search',
      'event_interaction': 'Modal',
      'event_type': 'Save search success'
    })
  }
}

export const searchResultDataLayer = {

  toggleMap(show) {
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'Search results page',
      'event_type': (show ? 'Show map' : 'Show list')
    })
  },

  searchButtonClick(type) {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })

    const compareType = caseType(type)
    const verticalType = cond([
      compareType(houseSearchType.residential, 'buy'),
      compareType(houseSearchType.rent, 'rent'),
      compareType(houseSearchType.sold, 'sold'),
      compareType(houseSearchType.newHomes, 'new home'),
      compareType(houseSearchType.commercialForSale, 'commercial for sale'),
      compareType(houseSearchType.commercialForLease, 'commercial for lease'),
      compareType(houseSearchType.business, 'businesses for sale'),
      compareType(houseSearchType.rural, 'rural'),
    ])()

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': verticalType
    })
  },

  filter: {
    confirm() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'filters',
        'event_interaction': 'show',
        'event_type': 'search'
      })
    },

    clear() {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'filters',
        'event_interaction': 'clear',
        'event_type': 'search'
      })
    }
  }
}