'use client'

import { useMemo } from 'react'
import { LoginContext, useCloseDialog } from './context'
import useLogin from '@hooks/useLogin'
import dynamic from 'next/dynamic'
import LoginBase, { SkeletonCard } from './base'
import { useBoolean } from 'react-use'
import { useRecoilValue } from 'recoil'
import { login } from '@store/index'
const dynamicLoading = () => <SkeletonCard></SkeletonCard>
const SignIn = dynamic(() => import('./signin'), { ssr: false, loading: dynamicLoading })
const SignUp = dynamic(() => import('./signup'), { ssr: false, loading: dynamicLoading })
const Fotgot = dynamic(() => import('./forgot'), { ssr: false, loading: dynamicLoading })

export default function LoginDialog() {

  const { signin, signup, forgot } = useRecoilValue(login)
  const [loading, toggleLoading] = useBoolean(false)
  const { isLogin } = useLogin()
  const [viewSignin, viewSignup, viewForgot] = useMemo(() => {
    return [
      signin && !isLogin,
      signup && !isLogin,
      forgot && !isLogin,
    ]
  }, [signin, signup, forgot, isLogin])
  const [title, sub] = useMemo(() => {
    let title = 'Welcome to OneRoof'
    let sub = 'Sign in'
    if (viewSignup) {
      title = 'Sign up'
      sub = 'Welcome to OneRoof'
    } else if (viewForgot) {
      title = 'Forgot password?'
      sub = `Enter your email address and
      we’ll send you reset instructions.`
    }
    return [
      title,
      sub
    ]
  }, [viewSignup, viewForgot])

  const closeHandle = useCloseDialog()
  return (
    <LoginContext.Provider value={{ toggleLoading }}>
      <LoginBase show={viewSignin || viewSignup || viewForgot} loading={loading} title={title} sub={sub} onClose={closeHandle}>
        {viewSignin && <SignIn></SignIn>}
        {viewSignup && <SignUp></SignUp>}
        {viewForgot && <Fotgot></Fotgot>}
      </LoginBase>
    </LoginContext.Provider>
  )
}