'use client'

import Avatar from '@component/avatar'
import classNames from 'classnames'
import { map, range, size } from 'lodash-es'
import { createContext, useContext, useMemo, useState } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.css'

const AgentsCarouselContext = createContext()
export function useAgentsCarousel() {
  return useContext(AgentsCarouselContext)
}

export default function AgentsCarousel({
  name = 'AgentsCarousel',
  data = [],
  defaultSlide = 0,
  className = '',
  children,
  rightSlot
}) {

  const agentCount = size(data)
  const showCarousel = agentCount >= 3
  const viewData = useMemo(() => {
    if (showCarousel) {
      return [...data, ...data]
    } else {
      return data
    }
  }, [data, showCarousel])

  const [visible, setVisible] = useState(!showCarousel)
  const [realIndex, setRealIndex] = useState(defaultSlide)

  const leftName = `${name}-agent-prev`
  const rightName = `${name}-agent-next`
  const state = useMemo(() => {
    return {
      currentAgent: viewData[realIndex],
      visible,
      leftName,
      rightName
    }
  }, [viewData, realIndex, visible, leftName, rightName])

  return agentCount > 0 && (
    <AgentsCarouselContext.Provider value={state}>
      {
        showCarousel
          ? (
            <AgentsCarouselContent
              data={viewData}
              defaultSlide={defaultSlide}
              rightSlot={rightSlot}
              name={name}
              className={className}
              visible={visible}
              onSwiper={() => setVisible(true)}
              realIndex={realIndex}
              onSlideChange={sw => setRealIndex(sw.realIndex)}
            />
          )
          : (
            <AgentsNormalContent
              data={data}
              defaultSlide={defaultSlide}
              rightSlot={rightSlot}
              className={className}
              realIndex={realIndex}
              onSlideChange={index => setRealIndex(index)}
            />
          )
      }
      {children}
    </AgentsCarouselContext.Provider>
  )
}

function AgentsNormalContent({
  data,
  className = '',
  realIndex,
  onSlideChange,
  rightSlot
}) {
  const agentCount = size(data)

  function handlePrev() {
    if (realIndex > 0) {
      onSlideChange(realIndex - 1)
    } else {
      onSlideChange(agentCount - 1)
    }
  }
  function handleNext() {
    if (realIndex < agentCount - 1) {
      onSlideChange(realIndex + 1)
    } else {
      onSlideChange(0)
    }
  }

  return (
    <div className={classNames('text-center', className)}>
      <div className='inline-flex items-center gap-x-4'>
        {
          agentCount > 1 && <CarouselNavButton left onClick={handlePrev} />
        }
        <div className='flex items-center h-90 px-15'>
          {
            map(data, (agent, idx) => (
              <div
                key={idx}
                className={classNames(
                  '!w-60 !h-60 !flex items-center justify-center transition-opacity',
                  { 'z-[2]': realIndex === idx },
                  [realIndex - 1, realIndex + 1, realIndex].includes(idx) ? 'opacity-100' : 'opacity-0'
                )}
              >
                <AgentItem
                  data={agent}
                  active={realIndex === idx}
                  onClick={() => onSlideChange(idx)}
                />
              </div>

            ))
          }
        </div>
        {rightSlot}
        {
          agentCount > 1 && <CarouselNavButton right onClick={handleNext} />
        }
      </div>
    </div>
  )
}

function AgentsCarouselContent({
  data,
  defaultSlide,
  rightSlot,
  name,
  className,
  visible,
  onSwiper,
  realIndex,
  onSlideChange
}) {
  const leftName = `${name}-agent-prev`
  const rightName = `${name}-agent-next`

  return (
    <div className={classNames('relative', className)}>
      {
        !visible && <SkeletonAgents className='absolute inset-0 w-full h-full' />
      }
      <div className='flex items-center justify-center gap-x-6'>
        <CarouselNavButton left />
        <Swiper
          watchSlidesProgress
          slidesPerView='auto'
          centeredSlides
          spaceBetween={-20}
          loop
          initialSlide={defaultSlide}
          modules={[Navigation]}
          className='!py-15 !mx-0'
          style={{ width: 210, opacity: visible ? 1 : 0 }}
          onSlideChange={onSlideChange}
          onSwiper={onSwiper}
          slideToClickedSlide
          navigation={{
            nextEl: '.' + rightName,
            prevEl: '.' + leftName
          }}
          onProgress={(swiper) => {
            swiper.slides.forEach((slide) => {
              const progress = slide.progress
              slide.style.transform = `scale(${1 - Math.min(Math.abs(progress / 4.5), 1)}) translate3d(0px,0,${-Math.abs(10 * progress)}px)`
            })
          }}
          onSetTransition={(swiper, transition) => {
            swiper.slides.forEach((slide) => {
              slide.style.transitionDuration = transition + 'ms'
            })
          }}
        >
          {
            map(data, (agent, idx) => (
              <SwiperSlide
                key={idx}
                className={classNames(
                  '!w-90 !h-90',
                  { 'z-[2]': realIndex === idx },
                )}
              >
                <AgentItem data={agent} active blur={realIndex !== idx} />
              </SwiperSlide>
            ))
          }
        </Swiper>
        {rightSlot}
        <CarouselNavButton right />
      </div>
    </div>
  )
}

function CarouselNavButton({
  disabled = false,
  left = false,
  right = false,
  onClick
}) {
  const { visible, leftName, rightName } = useAgentsCarousel()
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'w-32 h-32 flex items-center justify-center text-primary disabled:opacity-40 disabled:pointer-events-none',
        { [leftName]: left },
        { [rightName]: right },
        { 'opacity-0': !visible }
      )}>
      <i className={classNames('icon font-bold !text-xl', { 'icon-arrow-left': left }, { 'icon-arrow-right': right })}></i>
    </button>
  )
}

function AgentItem({
  data,
  active = false,
  blur = !active,
  className = '',
  onClick
}) {
  return (
    <div
      className={classNames(
        'block transition-all relative rounded-half overflow-hidden flex-shrink-0',
        active ? 'border-2 border-white w-90 h-90' : 'w-70 h-70',
        className
      )}
      onClick={onClick}
    >
      <div className={classNames('absolute inset-0 bg-black rounded-half', !blur ? 'bg-opacity-5' : 'bg-opacity-25 backdrop-blur-[1px]')}></div>
      <Avatar
        width={90}
        height={90}
        src={data.icon}
        alt={data.name}
        circle
        defaultIcon='icon-avatar'
        iconSize='!text-2xl'
        className='!w-full !h-full bg-white'
      />
    </div>
    // <Avatar src={data.icon} width={110} height={110} alt={data.name} circle defaultIcon='icon-avatar' iconSize='!text-2xl' className={getColliersClassName(house.office)}></Avatar>
  )
}

function SkeletonAgents({
  className = ''
}) {
  return (
    <div className={classNames('flex items-center justify-center skeleton-wrap h-90', className)}>
      {
        map(range(3), idx => (
          <div className={classNames('w-60 h-60 flex items-center justify-center', { 'relative z-[1]': idx === 1 })} key={idx}>
            <span className={classNames('flex-shrink-0 !rounded-half', idx === 1 ? 'w-90 h-90 border-2 border-white' : 'w-70 h-70')}></span>
          </div>
        ))
      }
    </div>
  )
}
