'use client'

import AppendRoot from '@component/appendRoot'
import ListingsStandard from '@component/card/listings/standard'
import CloseButton from '@component/closeButton'
import FullPopup from '@component/fullPopup'
import SectionSwiper from '@component/layout/section/swiper'
import MaskLoading from '@component/loading/mask'
import { useOpenSignupDialog } from '@component/login/context'
import useFetch from '@hooks/useFetch'
import { isSoldData } from '@utils/house'
import { get, isFunction, size, take } from 'lodash-es'

export default function EnquirySent({
  house,
  show = false,
  onClose
}) {

  const openSignupDialog = useOpenSignupDialog('agent_enquiry')

  function signupHandle() {
    isFunction(onClose) && onClose()
    openSignupDialog()
  }

  const params = { limit: 3 }
  if (isSoldData(house)) {
    params.source = 1
  } else {
    params.source = 2
  }
  const houseId = get(house, 'houseId')
  const { data: list, isLoading } = useFetch(houseId ? {
    url: `house/${houseId}/similar`,
    params
  } : null)

  return (
    <AppendRoot>
      <FullPopup show={show} verifyPointingDevice={false} smCenter mobileFull contentClassName='!bg-transparent !top-auto bottom-0 sm:px-10 sm:!top-1/2 sm:bottom-auto' id='enquiry-send'>
        <div className='w-[950px] overflow-auto max-h-fill-screen max-w-full rounded-t-2xl sm:rounded-sm bg-white relative p-16 sm:px-58 sm:py-50'>
          <CloseButton className='absolute right-10 top-10' onClick={onClose} />
          <div className='sm:text-center flex flex-col gap-y-20 sm:gap-y-16'>
            <div className='w-52 h-52 rounded-half bg-secondary grid place-items-center sm:mx-auto'>
              <i className='icon icon-correct text-white !text-3xl font-bold'></i>
            </div>
            <div className='text-3xl font-medium'>Enquiry Sent</div>
            <div className='text-1xl'>We have received your enquiry, an agent will be in touch shortly.</div>
            {/* {
              !isLogin && (
                <div className='text-xl'>To keep track of your enquiry, <span className='cursor-pointer text-primary underline' onClick={signupHandle}>sign up here.</span></div>
              )
            } */}
            {
              size(list) > 0 && (
                <>
                  <div className='border-t-2 border-t-neutral-2 sm:hidden'></div>
                  <div className='grid text-xl font-medium sm:grid-cols-[1fr_auto_1fr] sm:before:h-2 sm:before:bg-neutral-2 sm:after:h-2 sm:after:bg-neutral-2 sm:items-center sm:gap-x-10 sm:mt-40'>Recommended for you</div>
                  <SectionSwiper
                    data={take(list, 3)}
                    dataKey='id'
                    slideClassName='py-12'
                    swiperClassName='-mb-12 -mt-20'
                    slidesPerView={3}
                    Card={ListingsStandard}
                    cardParams={{ useFavorite: false, target: '_blank' }}
                  ></SectionSwiper>
                </>
              )
            }
          </div>
          <MaskLoading loading={isLoading}></MaskLoading>
        </div>
      </FullPopup>
    </AppendRoot>
  )
}
